const CurvatureIcon = () => {
  return (
    <svg
      width="9"
      height="32"
      viewBox="0 0 9 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0L1 7.99988C1 12.4182 4.58172 15.9999 9 15.9999V15.9999"
        stroke="#5C5C5C"
      />
    </svg>
  );
};

export default CurvatureIcon;
