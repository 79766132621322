import styles from "./Section.module.css";
import { ReactNode } from "react";

type Props = {
  label: string;
  actionText?: string;
  onActionClick?: () => void;
  children: ReactNode;
  containerStyles?: string;
};

const Section = ({
  label,
  actionText,
  onActionClick,
  children,
  containerStyles = "",
}: Props) => {
  return (
    <div className={`${styles.container} ${containerStyles}`}>
      <div className={styles.header}>
        <span className={styles.label}>{label}</span>
        <button onClick={onActionClick} className={styles.actionText}>
          {actionText}
        </button>
      </div>
      {children}
    </div>
  );
};

export default Section;
