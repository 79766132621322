const StraightWithCurvatureIcon = () => {
  return (
    <svg
      width="9"
      height="32"
      viewBox="0 0 9 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00001 16V16C4.58173 16 1.00001 12.4183 1.00001 8L1.00001 0L1 32"
        stroke="#5C5C5C"
      />
    </svg>
  );
};

export default StraightWithCurvatureIcon;
