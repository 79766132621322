//import { find, findIndex, split, last, map, set, assignIn } from 'lodash'


export type TNodes = {
  displayName: string,
  id: string,
  level: string,
  objectIDs: string[],
  primaryTwinID: string,
}

export type TSidebarTree = {
  displayName: string,
  id: string,
  name: string,
  level: string,
  nodes?: TNodes[],
  objectIDs: string[],
  primaryTwinID: string,
}

// @ts-ignore
const generateSideBarItems = (menuData): TSidebarTree[] => {

  const transformedMenu: TSidebarTree[] = [
    {
      id: menuData?.id,
      displayName: menuData?.displayName,
      name: "office"
    },
    ...(menuData?.nodes.map((node: any, i: any) => ({
      name: `floor${i + 1}`,
      displayName: node.displayName,
      objectIDs: node.objectIds,
      id: node.id,
      primaryTwinID: node.primaryTwinId,
      level: node.level,
      nodes: node.nodes.map((subNode: any) => ({
        displayName: subNode.displayName,
        objectIDs: subNode.objectIds,
        id: subNode.id,
        level: subNode.level,
        primaryTwinID: subNode.primaryTwinId,
        nodes: subNode.nodes,
      })),
    })))
  ];

  return transformedMenu
};

export default generateSideBarItems;
