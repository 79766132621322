import { get, values, find, has } from "lodash";
import MotionSensor from "../icons/hardware/MotionSensor";
import Temperature from "../icons/hardware/Temperature";
import Humidity from "../icons/hardware/Humidity";
import Electricity from "../icons/hardware/Electricity";
import CO2 from "../icons/hardware/CO2";
import Fan from "../icons/hardware/Fan";
import Lamp from "../icons/hardware/Lamp";
import Person from "../icons/hardware/Person";
import { useGlobal } from "../../contexts/GlobalContext";

export function getNotificationText(deviceTypeCode: string, status: boolean, value: string) {
  switch (deviceTypeCode) {
    case "MOTION": {
      if (status) {
        return "Room is occupied";
      }
      
      return "Room is vacant";
    }
    case "TEMPERATURE": {
      const numberValue = Number(value);
      
      if (numberValue < 18) {
        return "Temperature below normal";
      }
      
      if (numberValue >= 20 || numberValue <= 22) {
        return "Temperature is optimal";
      }
      
      if (numberValue > 22) {
        return "Temperature above normal";
      }
      
      break;
    }
    
    case "HUMIDITY": {
      const numberValue = Number(value);
      
      if (numberValue >= 40 || numberValue <= 60) {
        return "Humidity is optimal";
      }
      
      if (numberValue < 40) {
        return "Humidity below normal";
      }
      
      if (numberValue > 60) {
        return "Humidity above normal";
      }
      
      break;
    }
    
    case "LIGHT": {
      if (status) {
        return "Lights switched on";
      }
      
      return "Lights switched off";
    }
    
    case "CO2": {
      const numberValue = Number(value);
      
      if (numberValue >= 250 || numberValue <= 400) {
        return "CO2 level optimal";
      }
      
      if (numberValue > 400) {
        return "CO2 level above normal";
      }
      
      return "CO2 level below normal";
    }
    
    case "FAN": {
      if (status) {
        return "Fan started";
      }
      
      return "Fan stopped";
    }
    
    case "OVERCROWDED": {
      return "Area overcrowded";
    }
    
    default: {
      return deviceTypeCode;
    }
  }
}

export function getHardwareIcon(deviceTypeCode: string) {
  
  const iconsEnum = {
    "MOTION": MotionSensor,
    "TEMPERATURE": Temperature,
    "HUMIDITY": Humidity,
    "ELECTRICITY": Electricity,
    "CO2": CO2,
    "FAN": Fan,
    "LIGHT": Lamp,
    "PEOPLE_COUNT": Person,
    "OVERCROWDED": Person
  };
  
  // @ts-ignore
  const IconComponent  = iconsEnum?.[deviceTypeCode];
  
  return IconComponent ? <IconComponent/> : null
}

const useNotificationSelector = ({ id, uuid }: { id: string, uuid: string }) => {
  const { notifications, sensors, actuators } = useGlobal();
  
  const notification = get(notifications, uuid, {});
  const currentNotification = get(notification, id);
  
  const sensorCollection = get(sensors, uuid, {});
  const actuatorsCollection = get(actuators, uuid, {});
  const hardware = [...values(sensorCollection), ...values(actuatorsCollection)];
  
  const notificationWithDeviceCode = has(currentNotification, "deviceTypeCode")
    ? currentNotification
    : find(hardware, { id }) ?? {};
  
  const deviceTypeCode = get(notificationWithDeviceCode, "deviceTypeCode", "");
  const statusHardware = get(notificationWithDeviceCode, "isActive", false);
  const value = get(notificationWithDeviceCode, "value", "0");
  
  return {
    Icon: getHardwareIcon(deviceTypeCode),
    text: getNotificationText(deviceTypeCode, statusHardware, value)
  };
};

export default useNotificationSelector;
