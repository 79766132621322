
import {TNodes} from '../components/tree/generateSideBarItems'

function convertPrimaryTwinIdToUID(item: TNodes | string): string {
    if(typeof item === 'string') {
        return item.split('.').join('')
    }

    return item?.primaryTwinID?.split('.').join('')
  }

  export default convertPrimaryTwinIdToUID
