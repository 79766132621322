import styles from "./Jarvis.module.css";

interface Props {
  text?: string;
  height?: number;
}

const Animation: React.FC<Props> = ({ text = "", height = 300 }) => {
  const style = {
    height: `${height}px`,
  };

  return (
    <div className={styles.container} style={style}>
      {text && <p>{text}</p>}
    </div>
  );
};

export default Animation;
