import { flushSync } from "react-dom";

const useTransitionApi = (callback: (...args: any[]) => void) => {
  return (...args: any[]) => {
    if ("startViewTransition" in document) {
      document.startViewTransition(() => {
        flushSync(() => callback(...args));
      });
    } else {
      callback(...args);
    }
  };
};

export default useTransitionApi;
