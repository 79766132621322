import NotificationsIcon from "../icons/navigation/notifications";
import SettingsIcon from "../icons/navigation/settings";
import LeaveAFeedbackIcon from "../icons/navigation/leave-a-feedback";

const routes = (user: { name: string; role: string; avatar: string }) => [
  {
    title: user.name,
    subtitle: user.role,
    icon: user.avatar,
  },
  {
    title: "Notifications",
    subtitle: "",
    icon: NotificationsIcon,
  },
  {
    title: "Settings",
    subtitle: "",
    icon: SettingsIcon,
  },
  {
    title: "Leave a feedback",
    subtitle: "",
    icon: LeaveAFeedbackIcon,
  },
];

export default routes;
