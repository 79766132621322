const PlayIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon_play">
      <circle id="Ellipse 337" cx="18" cy="18" r="18" fill="#0170FD" />
      <path id="Vector 334" d="M14 26V8L25 17.5L14 26Z" fill="white" />
    </g>
  </svg>
);

export default PlayIcon;
