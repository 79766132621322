import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { first } from "lodash";
import { AuthenticationResult } from '@azure/msal-browser';

import Model3d from './pages/model3d/model3d'
import ScenarioWrapper from "./pages/scenario/ScenarioWrapper";
import Layout from "./pages/Layout";
import { ModalProvider } from "./components/modal";
import { GlobalProvider } from "./contexts/GlobalContext";
import NotificationInitializator from "./components/notifications/NotificationInitializator";
import { TAppProps } from "./ts/general";
import Api from './api'
import OnBoarding from "./components/on-boarding-process/OnBoarding";

const queryClient = new QueryClient({})

const App: React.FC<TAppProps> = ({ appData }) => {
  const { config, scenes, msalConfig, popupRequest, authToken } = appData;
  const api = new Api(config.bfeBaseUrl!, authToken as AuthenticationResult);

  return (
    <GlobalProvider defaultState={{ config, scenes, sceneId: first(scenes)?.id, msalConfig, popupRequest, api }}>
      <NotificationInitializator negotiationApiBaseUrl={config.bfeBaseUrl!} token={authToken?.idToken || ''} />
      <BrowserRouter>
        <ModalProvider>
          <QueryClientProvider client={queryClient}>
            <OnBoarding>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="" element={<Model3d />} />
                  <Route path="scenario/:scenarioId" element={<ScenarioWrapper />} />
                </Route>
              </Routes>
            </OnBoarding>
          </QueryClientProvider>
        </ModalProvider>
      </BrowserRouter>
    </GlobalProvider>
  );
};

export default App;
