const SwapFlowsOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=swap_flows">
      <path
        id="icon/action/swap_horizontal_circle_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12ZM15 6.5V9H11V11H15V13.5L18.5 10L15 6.5ZM5.5 14L9 10.5V13H13V15H9V17.5L5.5 14Z"
        fill="white"
      />
    </g>
  </svg>
);

export default SwapFlowsOutlined;
