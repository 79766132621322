const TurnstileUnblocked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 11.5C20.5 7.09 16.91 3.5 12.5 3.5C11.09 3.5 9.77 3.87 8.63 4.51L7.17 3.05C8.71 2.07 10.54 1.5 12.5 1.5C18.02 1.5 22.5 5.98 22.5 11.5C22.5 13.46 21.93 15.29 20.95 16.84L19.49 15.38C20.13 14.23 20.5 12.91 20.5 11.5ZM14.62 10.5H17.5V12.5H16.62L14.62 10.5ZM2.91 1.63L1.5 3.04L4.28 5.82C3.16 7.43 2.5 9.39 2.5 11.5C2.5 17.02 6.98 21.5 12.5 21.5C14.61 21.5 16.57 20.84 18.18 19.72L20.96 22.5L22.37 21.09L2.91 1.63ZM4.5 11.5C4.5 15.91 8.09 19.5 12.5 19.5C14.06 19.5 15.5 19.05 16.73 18.27L10.96 12.5H7.5V10.5H8.96L5.73 7.27C4.95 8.5 4.5 9.94 4.5 11.5Z"
      fill="white"
    />
  </svg>
);

export default TurnstileUnblocked;
