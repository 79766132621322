import { FC } from 'react';

import { IModalConfig } from './types';

import styles from './ModalComponent.module.css';

export const ModalComponent: FC<IModalConfig> = ({
  isOpen,
  title = '',
  cancel = {
    onClick: () => { },
  },
  content = null,
}) => (
  <div className={`${styles.modal} ${isOpen ? styles.isActive : ''}`}>
    <button className={styles.back} onClick={cancel.onClick}>↩ Back</button>
    <h1 className={styles.title}>{title}</h1>
    {content}
  </div>
);