import { useGlobal } from "../../../contexts/GlobalContext";

import RealNotificationsList from "./RealNotificationsList";

const RealScenarioNotifications: React.FC = () => {
  const { realScenarioNotifications } = useGlobal();

  return <RealNotificationsList notifications={realScenarioNotifications} />
};

export default RealScenarioNotifications;
