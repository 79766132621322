const HvacOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=HVAC">
      <path
        id="icon/hardware/toys_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23H11V16.43C9.93 17.4 8.52 18 7 18C3.75 18 1 15.25 1 12V11H7.57C6.6 9.93 6 8.52 6 7C6 3.75 8.75 1 12 1H13V7.57C14.07 6.6 15.48 6 17 6C20.25 6 23 8.75 23 12V13H16.43C17.4 14.07 18 15.48 18 17C18 20.25 15.25 23 12 23ZM13 20.87C14.7 20.41 16 18.83 16 17C16 15.17 14.7 13.59 13 13.13V20.87ZM7 16C5.17 16 3.59 14.7 3.13 13H10.87C10.41 14.7 8.83 16 7 16ZM20.87 11C20.41 9.3 18.82 8 17 8C15.18 8 13.59 9.3 13.13 11H20.87ZM8 7C8 5.18 9.3 3.59 11 3.13V10.87C9.3 10.41 8 8.82 8 7Z"
        fill="white"
      />
    </g>
  </svg>
);

export default HvacOutlined;
