import { useRef, MutableRefObject } from "react";
import mqtt, { MqttClient, IClientOptions } from "mqtt";

interface IMqttClient {
  client: MqttClient;
  connect(): void;
  publish(topic: string, message: string): void;
  subscribe(topic: string): void;
}

class SingletonMqttClient implements IMqttClient {
  public client: MqttClient;
  
  private static instance: IMqttClient;
  
  private constructor() {
    const BROKER_USER = "ss_mqtt_proxy_user";
    const BROKER_PASS = "123456";
    const BROKER_ADDRESS = "media-dev-intellitwin.intellias.com";
    const BROKER_PORT = "8882";
    // const BROKER_USER = "webuser";
    // const BROKER_PASS = "0diyZQyHiB4GBm+";
    // const BROKER_ADDRESS = "10.70.16.33";
    // const BROKER_PORT = "1883";
    
    const options: IClientOptions = {
      username: BROKER_USER,
      password: BROKER_PASS,
    };
    
    this.client = mqtt.connect(
      `wss://${BROKER_ADDRESS}:${BROKER_PORT}/mqtt`,
      options
    );
    
    console.log("mqtt::client_initialised");
  }
  
  public static getInstance(): IMqttClient {
    if (!SingletonMqttClient.instance) {
      SingletonMqttClient.instance = new SingletonMqttClient();
    }
    return SingletonMqttClient.instance;
  }
  
  public connect(): void {
    // Implement connection logic if needed
  }
  
  public publish(topic: string, message: string): void {
    this.client.publish(topic, message);
  }
  
  public subscribe(topic: string): void {
    this.client.subscribe(topic);
  }
}

const useMqtt = () => {
  const mqttRef: MutableRefObject<IMqttClient | null> = useRef(SingletonMqttClient.getInstance());

  return mqttRef.current as IMqttClient
};

export default useMqtt;
