const Co21Outlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=CO2_1">
      <path
        id="icon/file/cloud_circle_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12ZM16.29 10.19C15.89 8.18 14.13 6.67 12 6.67C10.31 6.67 8.85 7.63 8.12 9.03C6.36 9.21 5 10.7 5 12.5C5 14.43 6.57 16 8.5 16H16.08C17.69 16 19 14.69 19 13.08C19 11.54 17.8 10.29 16.29 10.19ZM8.5 14H16C16.55 14 17 13.55 17 13C17 12.45 16.55 12 16 12H14.61L14.33 10.58C14.11 9.47 13.13 8.67 12 8.67C11.11 8.67 10.3 9.16 9.89 9.95L9.4 11H8.5C7.67 11 7 11.67 7 12.5C7 13.33 7.67 14 8.5 14Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Co21Outlined;
