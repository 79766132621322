import {get, map, find, head} from 'lodash'

export default function getPrimaryIdByMeshId(menuItems: any[], meshToZoom: string) {
    const allMeshes = map(menuItems, item => item.nodes).flat();
  
    const currentMesh = find(allMeshes, mesh => {
      const objectId = head(get(mesh, 'objectIDs', []))
      return objectId === meshToZoom
    })
  
    return get(currentMesh, 'primaryTwinID', '')
  }
