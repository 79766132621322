import { AuthenticationResult } from '@azure/msal-browser';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {IApi} from '../ts/api/index';
import {TLevel, TArgument, TActuatorItem, TSensorItem} from '../ts/api/index';

export default class Api implements IApi{
  private instance: AxiosInstance;

  constructor(baseUrl: string, authToken: AuthenticationResult) {
    if (!authToken) {
      throw new Error('Auth token is not defined');
    }
    console.log('Initializing axios instance');
    
      this.instance = axios.create({
          baseURL: baseUrl,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken.idToken}`,
        },
      });
      this.instance.interceptors.response.use(
        async (response) => {
          return response;
        },
        async (error) => {
          if (error.response.status === 401) {
            sessionStorage.clear()
            window.location.reload();
          }
          return Promise.reject(error);
        }
      );

    console.log('Axios instance created');
  }

  public getStatistics = ({ primaryTwinID = 'building', level = 'building' }: { primaryTwinID: string; level: TLevel }): Promise<AxiosResponse<any>> => {
    return this.instance.get(`/${level}/${primaryTwinID}/statistics`);
  }

  public getActuators = ({id, level}: TArgument): Promise<AxiosResponse<TActuatorItem[]>> => {
    
    return this.instance.get(`/${level}/${id}/actuators`)
  }

  public getSensors = ({id, level}: TArgument): Promise<AxiosResponse<TSensorItem[]>> => {

    return this.instance.get(`/${level}/${id}/sensors`)
  }
  public getSidebarMenu = (): Promise<AxiosResponse<any[]>> => {

    return this.instance.get(`configuration/sidebar-menu`)
  }
}
