import { ReactElement } from "react";
import { useQuery } from "react-query";

import generateSideBarItems from "../tree/generateSideBarItems";
import { useGlobal } from "../../contexts/GlobalContext";

function OnBoarding({children}: { children: ReactElement }) {
  const {api, setMenuItems} = useGlobal();
  
  useQuery({
    queryKey: ['menus'],
    queryFn: () => api.getSidebarMenu(),
    onSuccess: (response: any) => {
      const menuData = response?.data ?? []
      const transformedMenu = generateSideBarItems(menuData)
      
      setMenuItems(transformedMenu)
    },
  })
  
  return children
}

export default OnBoarding
