import { useGlobal } from "../../contexts/GlobalContext";
import Notifications from "./Notifications";
import RealScenarioNotifications from './RealScenarioNotifications'
import { RECEPTION_LVIV, FLOOR6_ATOMIC, FLOOR6_PROPULSE } from '../../data'

export default function NotificationScenarioController() {
    const { meshToZoom } = useGlobal()

    const meshId = meshToZoom.at(0) ?? ''

    if ([RECEPTION_LVIV, FLOOR6_ATOMIC, FLOOR6_PROPULSE].includes(meshId)) {
        return <RealScenarioNotifications />
    }

    return <Notifications />
}