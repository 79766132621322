import {useEffect, useState} from "react";
import {isEmpty, isNull} from "lodash";
import {useNavigate} from "react-router-dom";
import useMqtt from "../../hooks/useMqtt";

import {useGlobal} from "../../contexts/GlobalContext";
// import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";
import renderCameraBox from "./renderCameraBox";
import {MQTT_SUBSCRIBE_TOPIC} from "./config";
import SceneManager from "./SceneManager";
import useNotificationControl from "../../hooks/useNotificationControl";
import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";
// import { drawAxis, getAllAngels, drawSceneAxes } from "./utils";

const {BABYLON} = window;

function log(name, data) {
  console.log.apply(console, [`useMotionDrawer::${name}::`, data]);
}

let instanceSceneManagerRef = null;
let meshToZoomRef = [];

function useMotionDrawer() {
  const [sceneInstance, persistScene] = useState(null);
  const {meshToZoom} = useGlobal();
  const {client} = useMqtt();
  const navigate = useNavigateWithTransition(useNavigate());
  const {addNotificationToCurrentPrimaryTwin, getNotificationById} = useNotificationControl();
  
  const hasNotification = getNotificationById("notificationFromScenescape");
  
  meshToZoomRef = meshToZoom;
  
  useEffect(() => {
    if (isEmpty(meshToZoom) || isNull(sceneInstance)) {
      return;
    }
    
    const roomMesh = sceneInstance.getMeshById(meshToZoom.at(0)); // get mesh instance
    
    // Note: draw X|Y|Z axes, for testing purpose
    // drawAxis({ scene: sceneInstance, mesh: roomMesh });
    // drawSceneAxes(sceneInstance)
    // getAllAngels(roomMesh, sceneInstance);
    
    instanceSceneManagerRef = new SceneManager({scene: sceneInstance, mesh: roomMesh});
    
    if (roomMesh instanceof BABYLON.Mesh) {
      renderCameraBox({
        scene: sceneInstance,
        position: new BABYLON.Vector3(-113, 4, -74.5),
        roomMesh,
        callback: (url) => navigate(url)
      });
      
      renderCameraBox({
        scene: sceneInstance,
        position: new BABYLON.Vector3(-113, 4, -59),
        roomMesh,
        callback: (url) => navigate(url)
      });
    }
    
    return () => {
      instanceSceneManagerRef?.reset();
      instanceSceneManagerRef = null;
    };
  }, [meshToZoom, sceneInstance]);
  
  useEffect(() => {
    if (client.connected) return;
    
    client.on("connect", () => {
      client.subscribe(MQTT_SUBSCRIBE_TOPIC);
    });
    
    client.on("message", (topic, message) => {
      if (topic !== MQTT_SUBSCRIBE_TOPIC) return;
      
      const messageString = message.toString();
      const messageObj = JSON.parse(messageString);
      
      if (instanceSceneManagerRef) {
        if (meshToZoomRef.includes("Floor 1 ShowRoom")) {
          instanceSceneManagerRef.render(messageObj);
        }
      }
      
      if (messageObj?.objects?.length >= 3 && !hasNotification) {
        addNotificationToCurrentPrimaryTwin({
          "id": "notificationFromScenescape",
          "deviceTypeCode": "OVERCROWDED",
          "value": 3,
        });
      }
    });
  }, [client]);
  
  return {persistScene};
}

export default useMotionDrawer;
