const VentilationIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7C5 3.68629 7.68629 1 11 1H11.5C11.7761 1 12 0.776142 12 0.5C12 0.223858 11.7761 0 11.5 0H11C7.13401 0 4 3.13401 4 7V8C4 8.55228 3.55228 9 3 9H0.5C0.223858 9 0 9.22386 0 9.5C0 9.77614 0.223858 10 0.5 10H3C4.10457 10 5 9.10457 5 8V7ZM11.5 4C9.567 4 8 5.567 8 7.5V8.5C8 10.9853 5.98528 13 3.5 13H0.5C0.223858 13 0 12.7761 0 12.5C0 12.2239 0.223858 12 0.5 12H3.5C5.433 12 7 10.433 7 8.5V7.5C7 5.01472 9.01472 3 11.5 3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H11.5ZM12 7C11.4477 7 11 7.44772 11 8V9C11 12.866 7.86599 16 4 16H3.5C3.22386 16 3 15.7761 3 15.5C3 15.2239 3.22386 15 3.5 15H4C7.31371 15 10 12.3137 10 9V8C10 6.89543 10.8954 6 12 6H15.5C15.7761 6 16 6.22386 16 6.5C16 6.77614 15.7761 7 15.5 7H12Z"
        fill="#479EF5"
      />
    </svg>
  );
};

export default VentilationIcon;
