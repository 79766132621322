import {useEffect, useRef} from 'react'


type TCountDown = {
  duration: number,
  callback: () => void
}

function runCountdown({callback, duration}: TCountDown) {
  let seconds = duration;
  
  function countdown() {
    seconds--;
    
    if (seconds < 0) {
      clearInterval(intervalId); // Stop the countdown
      callback(); // Run the provided callback after {duration} seconds
    }
  }
  
  const intervalId = setInterval(countdown, 1000); // Run countdown every second
  
  return intervalId;
}

const useDestroyAfter = ({duration, callback}: TCountDown) => {
  const counterRef = useRef(0);
  
  useEffect(() => {
    // @ts-ignore
    counterRef.current = runCountdown({duration, callback})
    
    return () => clearInterval(counterRef.current)
  }, []);
}

export default useDestroyAfter
