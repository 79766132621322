import {
  ROOM_DIMENSION_SCALE, ROOM_DIMENSION_X, ROOM_DIMENSION_Y,
  ROOM_DIMENSION_Z,
  SPHERE_COLOR,
  SPHERE_SHAPE_CONFIG,
} from "./config";
import {forEach, keys} from 'lodash'

const { BABYLON } = window;

  /**
 * @typedef {Object} Scene
 * @property {function} getMeshByUniqueId - A function to retrieve a mesh by its unique ID.
 * @property {function} computeWorldMatrix - A function to compute the world matrix of the scene.
 * @property {function} dispose - A function to dispose of the scene.
 * Add more properties/methods as needed...
 */

/**
 * @typedef {Object} Mesh
 * @property {number} uniqueId - The unique identifier of the mesh.
 * @property {Vector3} position - The position of the mesh.
 * @property {Vector3} scaling - The scaling of the mesh.
 * @property {BoundingInfo} getBoundingInfo - A function to get the bounding information of the mesh.
 * @property {function} dispose - A function to dispose of the mesh.
 * Add more properties/methods as needed...
 */

/**
 * @typedef {Object} Vector3
 * @property {number} x - The x-coordinate.
 * @property {number} y - The y-coordinate.
 * @property {number} z - The z-coordinate.
 */


class SceneManager {
  /**
 * Creates a new SceneManager instance.
 * @param {Object} options - The options object.
 * @param {Scene} options.scene - The scene reference for the SceneManager.
 * @param {Mesh} options.mesh - The mesh reference for the SceneManager.
 * @constructor
 */
  constructor({scene, mesh}) {
    this.landmarks = {};
    this.sceneRef = scene;
    this.meshRef = mesh;
  }

/**
 * Renders objects based on the provided message.
 * @param {Object} message - The message containing information about objects to render.
 * @param {Array<Object>} message.objects - An array of objects to be rendered.
 * @param {string} message.objects[].id - The unique identifier of the object.
 * @param {Vector3} message.objects[].translation - The translation values of the object.
 */
  render = (message) => {
    this._resetLendMarks(message.objects);

    // render each object in the message
    forEach(message.objects, (obj) => {
      const landmark = this.landmarks[obj.id];

      if (landmark) {
        const lendmarkMesh = this.sceneRef?.getMeshByUniqueId(obj.id);
        
        lendmarkMesh.position = this._computeGlobalPosition(obj)
;
        
        // lendmarkMesh.scaling.z *= -1
        // lendmarkMesh.rotate
      } else {
        this.landmarks[obj.id] = this._createGeometry(obj);
      }
    });
  }
  
  reset = () => {
    const landMarksListIds = new Set(keys(this.landmarks));

    landMarksListIds.forEach((obj) => {
      const sphere = this.sceneRef?.getMeshByUniqueId(this.landmarks[obj]);

      delete this.landmarks[obj];
      sphere.dispose();
    });
  }

  /**
 * Resets landmarks by removing expired ones and disposing of their associated meshes.
 */
  _resetLendMarks = (objects) => {
    const landMarksListIds = new Set(keys(this.landmarks));
    const newMarks = new Set();

    forEach(objects, (obj) => newMarks.add(String(obj.id)))

    forEach(newMarks, (obj) => landMarksListIds.delete(obj));

    landMarksListIds.forEach((obj) => {
      const inactive = this.sceneRef?.getMeshByUniqueId(this.landmarks[obj]);

      if (inactive) {
        delete this.landmarks[obj];
        inactive.dispose();
      }
    });
  }

  /**
 * Computes the global position of an object relative to the mesh's bounding box and world matrix.
 * @param {Object} object - The object for which the global position is computed.
 * @param {number[]} object.translation - The translation values of the object.
 */
  _computeGlobalPosition(object) {
    const { vectors } = this.meshRef?.getBoundingInfo().boundingBox;
    const minimum = vectors[0]; // origin position
    const worldMatrix = this.meshRef?.computeWorldMatrix(true);

    const originVector = new BABYLON.Vector3(0, 0, ROOM_DIMENSION_Y);
    
    const [x, y, z] = object.translation;
    
    console.log("_computeGlobalPosition", {x, y, z})

    const xMeters = x * ROOM_DIMENSION_SCALE.x;
    const yMeters = y * ROOM_DIMENSION_SCALE.y;
    const zMeters = ROOM_DIMENSION_Z * ROOM_DIMENSION_SCALE.z;

    const local_position_start = new BABYLON.Vector3(xMeters, zMeters, -yMeters).add(
      originVector
    );
    
    // worldMatrix.translation.z = ROOM_DIMENSION_X;
    
    const global_position_start = BABYLON.Vector3.TransformCoordinates(
      local_position_start,
      worldMatrix
    );
    
    return global_position_start;
  }

  /**
 * Creates a geometry representing an object.
 * @param {Object} object - The object for which geometry is created.
 * @param {string} object.id - The unique identifier of the object.
 * @param {Vector3} object.translation - The translation values of the object.
 * @returns {string} The unique identifier of the created geometry.
 */
  _createGeometry = (object) => {
    const sphere = BABYLON.MeshBuilder.CreateSphere(
      `sphere`,
      SPHERE_SHAPE_CONFIG,
      this.sceneRef,
      true
    );
    const material = new BABYLON.StandardMaterial(`circleMaterial`, this.sceneRef);
    material.diffuseColor = SPHERE_COLOR;
    sphere.material = material;

    sphere.uniqueId = object.id;
    
    sphere.position = this._computeGlobalPosition(object)

    return object.id;
  }
}

export default SceneManager
