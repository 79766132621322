import { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGlobal } from "../../contexts/GlobalContext";
import useVideoEvent from "../../hooks/useVideoEvent";
import { useVideoTimestamp } from "../../hooks/useVideoTimestamp";
import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";
import {
  TMeshIdKey,
  TVideoScenarioPage
} from "../../ts/general";

import VideoOverlay from "../../components/videoOverlay/VideoOverlay";
import styles from "./Scenario.module.css";
import noConnectionPlaceholder from "../../resources/images/disconnected.svg";
import { RECEPTION_LVIV, FLOOR6_ATOMIC, FLOOR6_PROPULSE } from "../../data";


const convertToSeconds = (timestamp: string) => {
  const [minutes, seconds] = timestamp.split(":").map(Number);
  
  return minutes * 60 + seconds;
};

const Scenario: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const video3dRef = useRef<HTMLVideoElement>(null);
  const timeoutRef = useRef([]);
  
  const [scheduledNotificationIds, setScheduledNotificationIds] = useState<
    Set<string>
  >(new Set());
  
  const [isMounted, setIsMounted] = useState(false);
  
  const { scenarioId } = useParams<{ scenarioId: TMeshIdKey }>();
  const {
    videosConfig,
    notificationsConfig,
    persistRealScenarioNotifications,
    realScenarioNotifications
  } = useGlobal();
  
  const isVideoLoaded = useVideoEvent(videoRef, "loadeddata");
  const isVideo3dLoaded = useVideoEvent(video3dRef, "loadeddata");
  
  const videoTimestamp = useVideoTimestamp(videoRef);
  
  const isVideoEnded = useVideoEvent(videoRef, "ended");
  const isVideo3dEnded = useVideoEvent(video3dRef, "ended");
  
  const smoothNavigate = useNavigateWithTransition(useNavigate());
  
  const isReady = isMounted && isVideoLoaded && isVideo3dLoaded;
  const isEnded = isVideoEnded && isVideo3dEnded;
  
  useEffect(() => {
    setIsMounted(true);
    
    return () => {
      setIsMounted(false);
      persistRealScenarioNotifications([]);
      setScheduledNotificationIds(new Set());
      timeoutRef.current.forEach(countId => {
        clearTimeout(countId);
      });
      
    };
  }, []);
  
  const { src, src3d, overlaySrc } = useMemo(
    () =>
      scenarioId
        ? videosConfig?.[scenarioId]?.scenarioPage
        : ({} as TVideoScenarioPage),
    [scenarioId, videosConfig]
  );
  
  useEffect(() => {
    if (scenarioId && isReady && !isEnded) {
      videoRef.current?.play();
      video3dRef.current?.play();
      
      if (![RECEPTION_LVIV, FLOOR6_ATOMIC, FLOOR6_PROPULSE].includes(scenarioId)) {
        return;
      }
      
      notificationsConfig?.[scenarioId]?.forEach(
        (notification, index, list) => {
          const notificationTimestamp = convertToSeconds(notification.timestamp);
          
          if (index < list.length - 1) {
            const nextNotificationTimestamp = convertToSeconds(list[index + 1].timestamp);
            notification.timeUntilNext = nextNotificationTimestamp - videoTimestamp;
          }
          
          const difference = (notificationTimestamp - videoTimestamp) * 1000;
          
          if (difference > 0 && !scheduledNotificationIds.has(notification.timestamp)) {
            
            // @ts-ignore
            timeoutRef.current[index] = setTimeout(() => {
              if (isMounted) {
                persistRealScenarioNotifications([notification]);
              }
              
            }, difference);
            
            setScheduledNotificationIds((ids) => new Set(ids).add(notification.timestamp));
          }
        }
      );
    }
    
    if (isEnded) {
      smoothNavigate("/");
      
    }
  }, [scenarioId, isReady, isEnded, videoTimestamp, isMounted]);
  
  console.log("videoTimestamp::", scenarioId, { videoTimestamp, scheduledNotificationIds, realScenarioNotifications });
  
  return (
    
    <div className={styles.container}>
      {overlaySrc ? (
        <div className={styles.grid}>
          <VideoOverlay
            src={src}
            overlaySrc={overlaySrc}
            poster={noConnectionPlaceholder}
          />
          
          <VideoOverlay
            src={src3d}
            overlaySrc={overlaySrc}
            poster={noConnectionPlaceholder}
          />
        </div>
      ) : (
        <>
          <video
            ref={video3dRef}
            className={styles.video}
            preload="auto"
            muted={true}
          >
            <source src={src3d} type="video/mp4" />
          </video>
          <video
            ref={videoRef}
            className={styles.video}
            preload="auto"
            muted={true}
          >
            <source src={src} type="video/mp4" />
          </video>
        </>
      )}
    </div>
  );
};

export default Scenario;
