import React from 'react'
import cn from "classnames";

import styles from "./Notifications.module.css";
import { useGlobal } from "../../contexts/GlobalContext";

import useDestroyAfter from "./useDestroyAfter";
import useNotificationSelector from "./useNotificationSelector";

type TProps = {
  id: string,
  uuid: string
}

const Notification: React.FC<TProps> = ({
  id,
  uuid
}) => {
  const {removeNotification} = useGlobal()
  const { Icon, text } = useNotificationSelector({ id, uuid })
  
  // Note: if `value` or `isActive` not changed
  // component is going to be self destroyed
  useDestroyAfter({
    duration: 25, // time in seconds
    callback: () => removeNotification({id, uuid})
  });

  return (
    <div className={cn(styles.notification, styles.notificationWrapper)}>
      <div className={styles.iconContainer}>{Icon}</div>

      <div className={styles.top}>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Notification;
