import React from "react";
import { head, values, get, find } from 'lodash'

import { motion, AnimatePresence } from "framer-motion";

import Section from "../section/Section";
import Animation from "../jarvis/Animation";
import Notification from "./Notification";

import { INotification } from "../../ts/general";

import styles from "./Notifications.module.css";
import { useGlobal } from "../../contexts/GlobalContext";
import { IActuator, ISensor } from "../../ts/api";
import pickActiveMenuItemByMeshId from '../../helpers/pickActiveMenuItemByMeshId'
import convertPrimaryTwinIdToUID from '../../helpers/convertPrimaryTwinIdToUID'

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};


const Notifications: React.FC<any> = () => {
  const { meshToZoom, menuItems, notifications, sensors, actuators } = useGlobal()

  const meshid = head(meshToZoom) || '';
  
  const activePage = pickActiveMenuItemByMeshId(menuItems, meshid)
  const uuid = convertPrimaryTwinIdToUID(activePage)
  
  const scenarioNotification = get(notifications, uuid, {})

  const notificationsList = values(scenarioNotification) as INotification[];
  const sensorList = values(get(sensors, uuid, {})) as ISensor[]
  const actuatorsList = values(get(actuators, uuid, {})) as IActuator[]

  const notificationWithExtendedData = notificationsList.map((notification) => {
    const maybeSensor = find(sensorList, {id: notification.id}) ?? {};
    const maybeActuator = find(actuatorsList, {id: notification.id}) ?? {};

    return {...maybeSensor, ...maybeActuator, ...notification}
  })
  
  
  return (
    <div className={styles.notificationsWrapper}>
      <Section label="Scenarios">
        {notificationWithExtendedData.length > 0 ? (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            className={styles.notificationsContainer}
          >
            <AnimatePresence>
              {notificationWithExtendedData.map(
                ({ id, isActive, value }: any) => (
                  <Notification key={`${id}-${isActive}-${value}`} id={id} uuid={uuid} />
                )
              )}
            </AnimatePresence>
          </motion.div>
        ) : (
          <Animation />
        )}
      </Section>
    </div>
  );
};

export default Notifications;
