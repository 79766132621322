import { useState, useEffect } from "react";

const useVideoEvent = (
  videoRef: React.RefObject<HTMLVideoElement>,
  eventName: string
): boolean => {
  const [eventFired, setEventFired] = useState<boolean>(false);

  useEffect(() => {
    const handleEvent = () => {
      setEventFired(true);
    };

    const videoElement = videoRef.current;

    if (videoElement && eventName) {
      videoElement.addEventListener(eventName, handleEvent);

      // Cleanup
      return () => {
        videoElement.removeEventListener(eventName, handleEvent);
      };
    }
  }, [videoRef]);

  return eventFired;
};

export default useVideoEvent;
