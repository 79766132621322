import { useCallback } from "react";
import {get} from 'lodash'
import { useGlobal } from "../contexts/GlobalContext"
import convertPrimaryTwinIdToUID from "../helpers/convertPrimaryTwinIdToUID";

export type TNotification = {
    "id":string,
    "name": string,
    "value": string | number,
    isActive: boolean,
    [key: string] : any
}

function useNotificationControl() {
    const { primaryTwinId, setNotification, notifications } = useGlobal();
    
    // setters
    const addNotificationToCurrentPrimaryTwin = useCallback((notification: TNotification) => {
        setNotification({
            message: { data: [notification] }
        })
    }, [setNotification, primaryTwinId])

    const persistNotificationToGlobalStore = useCallback((providedPrimaryId: string, notifications: {data: TNotification[]}) => {
        const uuid = convertPrimaryTwinIdToUID(providedPrimaryId as string);

        setNotification({
            uuid,
            message: { data: [...notifications.data] }
        })
    }, [])

    // getters
    const getNotificationById = useCallback((id: string) => {
        const uuid = convertPrimaryTwinIdToUID(primaryTwinId as string);

        const notificationPageCtx = get(notifications, uuid, '')

        return get(notificationPageCtx, id, null)
    } , [notifications, primaryTwinId])

    return {
        addNotificationToCurrentPrimaryTwin,
        persistNotificationToGlobalStore,
        getNotificationById,
    }
}

export default useNotificationControl
