import { v4 as uuidv4 } from "uuid";
import cloneDeep from "lodash/cloneDeep";

import { meshIds, allowedScenesIds, statisticsValueKeys } from "./constants";

import {
  IDevice,
  IWidgetData,
  TVideoConfig,
  TStatisticConfig,
  TNotificationsConfig,
  TAllowedScenesNameToIdMap,
} from "./ts/general";

import LightIcon from "./components/icons/devices/light";
import HeaterIcon from "./components/icons/devices/heater";
import SocketIcon from "./components/icons/devices/socket";
import HvacOutlined from "./components/icons/scenarios/HvacOutlined";
import Co21Outlined from "./components/icons/scenarios/Co21Outlined";
import EndOfScenario from "./components/icons/scenarios/EndOfScenario";
import Administrator from "./components/icons/scenarios/Administrator";
import VentilationIcon from "./components/icons/devices/ventilation";
import TurnstileBlocked from "./components/icons/scenarios/TurnstileBlocked";
import SwapFlowsOutlined from "./components/icons/scenarios/SwapFlowsOutlined";
import TurnstileUnblocked from "./components/icons/scenarios/TurnstileUnblocked";
import OvercrowdedOutlined from "./components/icons/scenarios/OvercrowdedOutlined";
import HealthAlertOutlined from "./components/icons/scenarios/HealthAlertOutlined";
import CleanEndingOutlined from "./components/icons/scenarios/CleanEndingOutlined";
import HealthIssueOutlined from "./components/icons/scenarios/HealthIssueOutlined";
import TemperatureOutlined from "./components/icons/scenarios/TemperatureOutlined";
import LightsDimmedOutlined from "./components/icons/scenarios/LightsDimmedOutlined";
import HealthRequestOutlined from "./components/icons/scenarios/HealthRequestOutlined";
import RobotOnTheWay1Outlined from "./components/icons/scenarios/RobotOnTheWay1Outlined";
import EnergyEfficiencyOutlined from "./components/icons/scenarios/EnergyEfficiencyOutlined";
import CleaningStartedOutlined from "./components/icons/scenarios/CleaningStartedOutlined";
import CleaningFinishedOutlined from "./components/icons/scenarios/CleaningFinishedOutlined";
import EmergencyOnTheWayOutlined from "./components/icons/scenarios/EmergencyOnTheWayOutlined";
import EmergencyArrived1Outlined from "./components/icons/scenarios/EmergencyArrived1Outlined";

const { OFFICE, FLOOR1, FLOOR6 } = allowedScenesIds;
export const { RECEPTION, FLOOR6_ATOMIC, FLOOR6_ENTERPRISE, FLOOR6_PROPULSE, RECEPTION_LVIV } = meshIds;
const { CO2, HUMIDITY, TEMPERATURE, OFFICE_OCCUPANCY, POWER_CONSUMPTION, SPACE_UTILIZATION, FLOOR_CONTAMINATION } = statisticsValueKeys;

export const hardware: Array<IDevice> = [
  {
    id: 1,
    icon: VentilationIcon,
    name: "Ventilation",
    identifier: "00:00:5e:00:53:01",
    priority: "P1",
    lastAction: "12/05/2023",
    status: "active",
    type: "sensor",
  },
  {
    id: 2,
    icon: HeaterIcon,
    name: "Heater",
    identifier: "00:00:5e:00:53:02",
    priority: "P1",
    lastAction: "12/05/2023",
    status: "active",
    type: "actuator",
  },
  {
    id: 3,
    icon: LightIcon,
    name: "Light",
    identifier: "00:00:5e:00:53:03",
    priority: "P2",
    lastAction: "12/05/2023",
    status: "inactive",
    type: "sensor",
  },
  {
    id: 4,
    icon: LightIcon,
    name: "Light",
    identifier: "00:00:5e:00:53:04",
    priority: "P2",
    lastAction: "12/05/2023",
    status: "active",
    type: "actuator",
  },
  {
    id: 5,
    icon: SocketIcon,
    name: "Socket",
    identifier: "00:00:5e:00:53:05",
    priority: "P3",
    lastAction: "12/05/2023",
    status: "active",
    type: "sensor",
  },
  {
    id: 6,
    icon: SocketIcon,
    name: "Socket",
    identifier: "00:00:5e:00:53:06",
    priority: "P3",
    lastAction: "12/05/2023",
    status: "inactive",
    type: "actuator",
  },
  {
    id: 7,
    icon: SocketIcon,
    name: "Socket",
    identifier: "00:00:5e:00:53:07",
    priority: "P3",
    lastAction: "12/05/2023",
    status: "outage",
    type: "sensor",
  },
  {
    id: 8,
    icon: SocketIcon,
    name: "Socket",
    identifier: "00:00:5e:00:53:08",
    priority: "P3",
    lastAction: "12/05/2023",
    status: "outage",
    type: "actuator",
  },
];

/**
 * clone to cut the objects references between the
 * meshData, hardware and indicators
 */
export const meshData = cloneDeep([
  {
    meshId: "Floor 1 Intelligence",
    sceneId: "40b0896ec1314ef5a1f2bea47b3e16ec",
    color: "#65C087",
    hardware,
  },
  {
    meshId: RECEPTION,
    sceneId: "40b0896ec1314ef5a1f2bea47b3e16ec",
    color: "#65C087",
    hardware,
  },
  {
    meshId: 'Floor 1 ShowRoom',
    sceneId: "40b0896ec1314ef5a1f2bea47b3e16ec",
    color: "#65C087",
    hardware,
  },
  {
    meshId: "Floor 1 Factorium",
    sceneId: "40b0896ec1314ef5a1f2bea47b3e16ec",
    color: "#65C087",
    hardware,
  },
  {
    meshId: "Wall Mesh.002",
    sceneId: "40b0896ec1314ef5a1f2bea47b3e16ec",
    color: "#65C087",
    hardware,
  },
  {
    meshId: FLOOR6_ATOMIC,
    sceneId: "3369fc3526f94cd7a77f0735782ef305",
    color: "#65C087",
    hardware,
  },
  {
    meshId: FLOOR6_ENTERPRISE,
    sceneId: "3369fc3526f94cd7a77f0735782ef305",
    color: "#65C087",
    hardware,
  },
]);

export const videosConfig: TVideoConfig = {
  [RECEPTION]: {
    mainPage: {
     isRealtime: true,
      src: "http://20.8.170.152:8889/customer0/overlay_pcam1/",
      labels: {
        camera: "webcam2",
        scenario: "real time video",
        showLiveLabel: true
      },
    },
    scenarioPage: {
      src3d: "http://20.8.170.152:8889/customer0/overlay_pcam0/",
      src: "http://20.8.170.152:8889/customer0/overlay_pcam1/",
      overlaySrc: "http://20.8.170.152:8889/customer0/overlay_pcam1/",
      // src3d: "video/scenarios/scenario_1_3d.mp4",
      // src: "video/scenarios/scenario_1.mp4",
    },
  },
  [RECEPTION_LVIV]: {
    mainPage: {
      src: "video/scenarios/scenario_1.mp4",
      labels: {
        camera: "Cam 1",
        scenario: "queue+cleaning scenarios",
      },
    },
    scenarioPage: {
      src3d: "video/scenarios/scenario_1_3d.mp4",
      src: "video/scenarios/scenario_1.mp4",
    },
  },
  [FLOOR6_PROPULSE]: {
    mainPage: {
      src: "video/scenarios/scenario_2.mp4",
      labels: {
        camera: "Cam 2",
        scenario: "Facility Management Scenario",
      },
    },
    scenarioPage: {
      src3d: "video/scenarios/scenario_2_3d.mp4",
      src: "video/scenarios/scenario_2.mp4",
    },
  },
  [FLOOR6_ATOMIC]: {
    mainPage: {
      src: "video/scenarios/scenario_3.mp4",
      labels: {
        camera: "Cam 3",
        scenario: "Emergency Scenario",
      },
    },
    scenarioPage: {
      src3d: "video/scenarios/scenario_3_3d.mp4",
      src: "video/scenarios/scenario_3.mp4",
    },
  },
  [FLOOR6_ENTERPRISE]: {
    mainPage: {
      src: "",
      labels: {
        camera: "Cam 4",
        scenario: "Enterprise Scenario",
      },
    },
    scenarioPage: {
      src3d: "",
      src: "",
    },
  },
};

export const widgetsData: Array<any> = [
  {
    title: "Office occupancy",
    value: "280/1200",
    description: "In-person / Max Occupancy",
  },
  {
    title: "Space utilization, (%)",
    value: "19/100",
    description: "Current / Max",
  },
  {
    title: "Power consumption, (kW)",
    value: "33.2",
    description: "per entire building",
  },
  {
    title: "Current energy efficiency, (%)",
    value: "86",
    description: "per entire building",
  },
  {
    title: "Daily incidents tracker",
    value: "12/1",
    description: "Minor / Major",
  },
];

export const allowedScenesNameToIdMap: TAllowedScenesNameToIdMap = {
  [OFFICE]: "d754039ca0614b8d8e4deccc1ae9f285",
  [FLOOR1]: "40b0896ec1314ef5a1f2bea47b3e16ec",
  [FLOOR6]: "3369fc3526f94cd7a77f0735782ef305",
};

export const notificationsConfig: TNotificationsConfig = {
  [RECEPTION_LVIV]: [
    {
      id: uuidv4(),
      timestamp: "00:20",
      text: "Start of the automated scenario “Queue is Overcrowded”",
      timeUntilNext: 0,
      icon: OvercrowdedOutlined,
      type: "primary",
    },
    {
      id: uuidv4(),
      timestamp: "00:25",
      text: "Turnstile is blocked / Alert notification highlighted",
      timeUntilNext: 0,
      icon: TurnstileBlocked,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "00:35",
      text: "Additional administrator request",
      timeUntilNext: 0,
      icon: Administrator,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "00:40",
      text: "Additional administrator is on the way",
      timeUntilNext: 0,
      icon: Administrator,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "00:45",
      text: "Additional administrator arrived at the point",
      timeUntilNext: 0,
      icon: Administrator,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:05",
      text: "Alert notification turned off/Turnstile is unblocked",
      timeUntilNext: 0,
      icon: TurnstileUnblocked,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:25",
      text: "The end of the automated scenario “Queue is Overcrowded”",
      timeUntilNext: 0,
      icon: EndOfScenario,
      type: "secondary",
    },
  ],
  [FLOOR6_ATOMIC]: [
    {
      id: uuidv4(),
      timestamp: "00:15",
      text: "Start of the automated scenario “Health Emergency”",
      timeUntilNext: 0,
      icon: HealthAlertOutlined,
      type: "emergency",
    },
    {
      id: uuidv4(),
      timestamp: "00:18",
      text: "Health issue alert",
      timeUntilNext: 0,
      icon: HealthIssueOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "00:22",
      text: "Security support request / Emergency team request",
      timeUntilNext: 0,
      icon: HealthRequestOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "00:27",
      text: "Security support is on the way / Emergency team is on the way",
      timeUntilNext: 0,
      icon: EmergencyOnTheWayOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "00:37",
      text: "Security support arrived",
      timeUntilNext: 0,
      icon: EmergencyArrived1Outlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:12",
      text: "End of the automated scenario “Health Emergency”",
      timeUntilNext: 0,
      icon: EndOfScenario,
      type: "secondary",
    },
  ],
  [FLOOR6_PROPULSE]: [
    {
      id: uuidv4(),
      timestamp: "00:45",
      text: "Start of the automated scenario “Energy Efficiency”",
      timeUntilNext: 0,
      icon: EnergyEfficiencyOutlined,
      type: "primary",
    },
    {
      id: uuidv4(),
      timestamp: "00:55",
      text: "Section lights dimmed",
      timeUntilNext: 0,
      icon: LightsDimmedOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:05",
      text: "HVAC system getting updates",
      timeUntilNext: 0,
      icon: HvacOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:10",
      text: "HVAC system starts",
      timeUntilNext: 0,
      icon: HvacOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:15",
      text: "HVAC system changes applied. 22.5° target set",
      timeUntilNext: 0,
      icon: TemperatureOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:25",
      text: "Air conditioning modified",
      timeUntilNext: 0,
      icon: HvacOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:35",
      text: "Air streams direction changed",
      timeUntilNext: 0,
      icon: SwapFlowsOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:40",
      text: "Air streams direction changed",
      timeUntilNext: 0,
      icon: SwapFlowsOutlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:50",
      text: "CO2 level decreasing",
      timeUntilNext: 0,
      icon: Co21Outlined,
      type: "secondary",
    },
    {
      id: uuidv4(),
      timestamp: "01:55",
      text: "End of the automated scenario “Energy Efficiency”",
      timeUntilNext: 0,
      icon: EndOfScenario,
      type: "secondary",
    },
  ],
};

export const statisticsConfig: TStatisticConfig = {
  [RECEPTION]: {
    timestamps: ["00:05", "00:10", "00:20", "00:30", "00:45", "00:55", "01:10", "01:25"],
    limits: {
      [OFFICE_OCCUPANCY]: 38,
      [SPACE_UTILIZATION]: 100,
      [FLOOR_CONTAMINATION]: 15,
      [POWER_CONSUMPTION]: 1.6,
      [CO2]: 550,
    },
    values: [
      { [OFFICE_OCCUPANCY]: 11, [SPACE_UTILIZATION]: 26, [FLOOR_CONTAMINATION]: 23, [POWER_CONSUMPTION]: 1.40, [TEMPERATURE]: 23.6, [HUMIDITY]: 57, [CO2]: 680 },
      { [OFFICE_OCCUPANCY]: 12, [SPACE_UTILIZATION]: 32, [FLOOR_CONTAMINATION]: 26, [POWER_CONSUMPTION]: 1.37, [TEMPERATURE]: 23.6, [HUMIDITY]: 56, [CO2]: 788 },
      { [OFFICE_OCCUPANCY]: 14, [SPACE_UTILIZATION]: 38, [FLOOR_CONTAMINATION]: 29, [POWER_CONSUMPTION]: 1.36, [TEMPERATURE]: 23.6, [HUMIDITY]: 56, [CO2]: 790 },
      { [OFFICE_OCCUPANCY]: 15, [SPACE_UTILIZATION]: 40, [FLOOR_CONTAMINATION]: 31, [POWER_CONSUMPTION]: 1.34, [TEMPERATURE]: 23.6, [HUMIDITY]: 56, [CO2]: 834 },
      { [OFFICE_OCCUPANCY]: 15, [SPACE_UTILIZATION]: 40, [FLOOR_CONTAMINATION]: 31, [POWER_CONSUMPTION]: 1.44, [TEMPERATURE]: 23.6, [HUMIDITY]: 56, [CO2]: 834 },
      { [OFFICE_OCCUPANCY]: 15, [SPACE_UTILIZATION]: 40, [FLOOR_CONTAMINATION]: 31, [POWER_CONSUMPTION]: 1.40, [TEMPERATURE]: 23.6, [HUMIDITY]: 56, [CO2]: 834 },
      { [OFFICE_OCCUPANCY]: 14, [SPACE_UTILIZATION]: 38, [FLOOR_CONTAMINATION]: 29, [POWER_CONSUMPTION]: 1.36, [TEMPERATURE]: 23.6, [HUMIDITY]: 56, [CO2]: 790 },
      { [OFFICE_OCCUPANCY]: 8, [SPACE_UTILIZATION]: 16, [FLOOR_CONTAMINATION]: 31, [POWER_CONSUMPTION]: 1.56, [TEMPERATURE]: 23.5, [HUMIDITY]: 54, [CO2]: 780 },
      { [OFFICE_OCCUPANCY]: 5, [SPACE_UTILIZATION]: 11, [FLOOR_CONTAMINATION]: 36, [POWER_CONSUMPTION]: 1.24, [TEMPERATURE]: 23.5, [HUMIDITY]: 54, [CO2]: 731 },
      { [OFFICE_OCCUPANCY]: 4, [SPACE_UTILIZATION]: 9, [FLOOR_CONTAMINATION]: 36, [POWER_CONSUMPTION]: 1.20, [TEMPERATURE]: 23.5, [HUMIDITY]: 54, [CO2]: 731 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 36, [POWER_CONSUMPTION]: 1.24, [TEMPERATURE]: 23.5, [HUMIDITY]: 54, [CO2]: 731 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 36, [POWER_CONSUMPTION]: 1.48, [TEMPERATURE]: 23.5, [HUMIDITY]: 54, [CO2]: 724 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 30, [POWER_CONSUMPTION]: 1.34, [TEMPERATURE]: 23.4, [HUMIDITY]: 52, [CO2]: 703 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 23, [POWER_CONSUMPTION]: 1.38, [TEMPERATURE]: 23.4, [HUMIDITY]: 52, [CO2]: 685 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 17, [POWER_CONSUMPTION]: 1.31, [TEMPERATURE]: 23.4, [HUMIDITY]: 52, [CO2]: 688 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 15, [POWER_CONSUMPTION]: 1.38, [TEMPERATURE]: 23.4, [HUMIDITY]: 53, [CO2]: 667 },
      { [OFFICE_OCCUPANCY]: 2, [SPACE_UTILIZATION]: 5, [FLOOR_CONTAMINATION]: 13, [POWER_CONSUMPTION]: 1.38, [TEMPERATURE]: 23.4, [HUMIDITY]: 53, [CO2]: 657 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 13, [POWER_CONSUMPTION]: 1.32, [TEMPERATURE]: 23.3, [HUMIDITY]: 53, [CO2]: 685 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 10, [POWER_CONSUMPTION]: 1.32, [TEMPERATURE]: 23.3, [HUMIDITY]: 53, [CO2]: 685 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 3, [FLOOR_CONTAMINATION]: 5, [POWER_CONSUMPTION]: 1.67, [TEMPERATURE]: 23.3, [HUMIDITY]: 53, [CO2]: 665 },
    ]
  },
  [FLOOR6_ENTERPRISE]: {
    timestamps: ["00:05", "00:10", "00:20", "00:30", "00:45", "00:55", "01:10", "01:25", "01:40", "01:50"],
    limits: {
      [OFFICE_OCCUPANCY]: 17,
      [SPACE_UTILIZATION]: 100,
      [FLOOR_CONTAMINATION]: 18,
      [POWER_CONSUMPTION]: 17.5,
      [CO2]: 964,
    },
    values: [
      { [OFFICE_OCCUPANCY]: 10, [SPACE_UTILIZATION]: 45, [FLOOR_CONTAMINATION]: 12, [POWER_CONSUMPTION]: 11.4, [TEMPERATURE]: 22.4, [HUMIDITY]: 59, [CO2]: 890 },
      { [OFFICE_OCCUPANCY]: 9, [SPACE_UTILIZATION]: 40, [FLOOR_CONTAMINATION]: 12, [POWER_CONSUMPTION]: 11.1, [TEMPERATURE]: 22.4, [HUMIDITY]: 59, [CO2]: 890 },
      { [OFFICE_OCCUPANCY]: 9, [SPACE_UTILIZATION]: 40, [FLOOR_CONTAMINATION]: 12, [POWER_CONSUMPTION]: 11.5, [TEMPERATURE]: 22.4, [HUMIDITY]: 59, [CO2]: 890 },
      { [OFFICE_OCCUPANCY]: 8, [SPACE_UTILIZATION]: 37, [FLOOR_CONTAMINATION]: 12, [POWER_CONSUMPTION]: 10.9, [TEMPERATURE]: 22.2, [HUMIDITY]: 56, [CO2]: 760 },
      { [OFFICE_OCCUPANCY]: 5, [SPACE_UTILIZATION]: 22, [FLOOR_CONTAMINATION]: 17, [POWER_CONSUMPTION]: 6.8, [TEMPERATURE]: 22.3, [HUMIDITY]: 54, [CO2]: 745 },
      { [OFFICE_OCCUPANCY]: 4, [SPACE_UTILIZATION]: 14, [FLOOR_CONTAMINATION]: 18, [POWER_CONSUMPTION]: 6.1, [TEMPERATURE]: 22.3, [HUMIDITY]: 54, [CO2]: 730 },
      { [OFFICE_OCCUPANCY]: 4, [SPACE_UTILIZATION]: 14, [FLOOR_CONTAMINATION]: 18, [POWER_CONSUMPTION]: 4.5, [TEMPERATURE]: 22.1, [HUMIDITY]: 54, [CO2]: 725 },
      { [OFFICE_OCCUPANCY]: 4, [SPACE_UTILIZATION]: 14, [FLOOR_CONTAMINATION]: 18, [POWER_CONSUMPTION]: 4.2, [TEMPERATURE]: 22.2, [HUMIDITY]: 56, [CO2]: 755 },
      { [OFFICE_OCCUPANCY]: 4, [SPACE_UTILIZATION]: 14, [FLOOR_CONTAMINATION]: 18, [POWER_CONSUMPTION]: 4.4, [TEMPERATURE]: 22.4, [HUMIDITY]: 56, [CO2]: 760 },
      { [OFFICE_OCCUPANCY]: 4, [SPACE_UTILIZATION]: 14, [FLOOR_CONTAMINATION]: 18, [POWER_CONSUMPTION]: 4.1, [TEMPERATURE]: 22.2, [HUMIDITY]: 56, [CO2]: 760 },
    ]
  },
  [FLOOR6_ATOMIC]: {
    timestamps: ["00:05", "00:30", "00:50", "01:00", "01:20", "01:30"],
    limits: {
      [OFFICE_OCCUPANCY]: 17,
      [SPACE_UTILIZATION]: 100,
      [FLOOR_CONTAMINATION]: 18,
      [POWER_CONSUMPTION]: 17.5,
      [CO2]: 964,
    },
    values: [
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 8, [FLOOR_CONTAMINATION]: 8, [POWER_CONSUMPTION]: 1.8, [TEMPERATURE]: 22.1, [HUMIDITY]: 55, [CO2]: 325 },
      { [OFFICE_OCCUPANCY]: 1, [SPACE_UTILIZATION]: 8, [FLOOR_CONTAMINATION]: 8, [POWER_CONSUMPTION]: 1.6, [TEMPERATURE]: 22.2, [HUMIDITY]: 55, [CO2]: 340 },
      { [OFFICE_OCCUPANCY]: 2, [SPACE_UTILIZATION]: 8, [FLOOR_CONTAMINATION]: 8, [POWER_CONSUMPTION]: 1.6, [TEMPERATURE]: 22.3, [HUMIDITY]: 57, [CO2]: 355 },
      { [OFFICE_OCCUPANCY]: 2, [SPACE_UTILIZATION]: 8, [FLOOR_CONTAMINATION]: 8, [POWER_CONSUMPTION]: 1.6, [TEMPERATURE]: 22.3, [HUMIDITY]: 58, [CO2]: 372 },
      { [OFFICE_OCCUPANCY]: 2, [SPACE_UTILIZATION]: 8, [FLOOR_CONTAMINATION]: 8, [POWER_CONSUMPTION]: 1.5, [TEMPERATURE]: 22.2, [HUMIDITY]: 58, [CO2]: 370 },
      { [OFFICE_OCCUPANCY]: 0, [SPACE_UTILIZATION]: 0, [FLOOR_CONTAMINATION]: 12, [POWER_CONSUMPTION]: 1.5, [TEMPERATURE]: 22.2, [HUMIDITY]: 58, [CO2]: 365 },
    ]
  }
};
