const CaretActiveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_253_7178)">
        <path
          d="M4.66675 6.66663L8.00008 9.99996L11.3334 6.66663H4.66675Z"
          fill="#479EF5"
        />
      </g>
      <defs>
        <clipPath id="clip0_253_7178">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CaretActiveIcon;
