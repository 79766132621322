import { ReactNode } from "react";
import HeaterIcon from "../components/icons/devices/heater";
import LightIcon from "../components/icons/devices/light";
import SocketIcon from "../components/icons/devices/socket";
import VentilationIcon from "../components/icons/devices/ventilation";

export const meshIds: Record<string, string> = {
  RECEPTION: "Reception",
  RECEPTION_LVIV: "Reception_Lviv",
  FLOOR6_ATOMIC: "Floor6 Atomic",
  FLOOR6_ENTERPRISE: "Floor6 Enterprise",
  FLOOR6_PROPULSE: "Floor6 Propulse",
  // 2aeac11c66fa45e7a55289c21d4a8e30
};

export const allowedScenesIds: Record<string, string> = {
  OFFICE: "office",
  FLOOR1: "floor1",
  FLOOR6: "floor6",
};

export const statisticsValueKeys: Record<string, string> = {
  CO2: "co2",
  HUMIDITY: "humidity",
  TEMPERATURE: "temperature",
  OFFICE_OCCUPANCY: "officeOccupancy",
  SPACE_UTILIZATION: "spaceUtilization",
  POWER_CONSUMPTION: "powerConsumption",
  FLOOR_CONTAMINATION: "floorContamination",
};

export const hardwareMap: Record<string, { icon: () => ReactNode; label: string; }> = {
  LIGHT: {
    icon: LightIcon,
    label: "Light",
  },
  SOCKET: {
    icon: SocketIcon,
    label: "Socket",
  },
  HEATER: {
    icon: HeaterIcon,
    label: "Heater",
  },
  VENTILATION:{
    icon: VentilationIcon,
    label: "Ventilation",
  },
}
