import Skeleton from 'react-loading-skeleton'

import { IWidgetData } from "../../ts/general";
import ArrowDownRed from '../icons/ArrowDownRed';
import ArrowUpGreen from '../icons/ArrowUpGreen';

import 'react-loading-skeleton/dist/skeleton.css'
import styles from "./Stats.module.css";

export const WidgetSkeleton: React.FC = () => (
  <div className={styles.widget}>
    <div className={styles.title}>
      <Skeleton baseColor='#1a235b' width={100} height={10} />
    </div>
    <div className={styles.value}>
      <Skeleton baseColor='#1a235b' width={80} height={20} />
    </div>
    <div className={styles.description}>
      <Skeleton baseColor='#1a235b' width={60} height={9} />
    </div>
  </div>
);

export const Widget: React.FC<IWidgetData> = ({ name, type, norm, value, period }) => {
  const [descriptionNum, descriptionDenom] = period.split("/");
  return (
    <div className={styles.widget}>
      <div className={styles.title}>
        <span>{name}</span>,
        <span className={styles.type}>{type}</span>
      </div>
      <div className={styles.value}>
        <span>{value}</span>
        {norm && <span className={styles.fraction}>/{norm}</span>}
      </div>
      <div className={styles.description}>
        <span>{descriptionNum}</span>
        {descriptionDenom && (
          <span className={styles.fraction}>/{descriptionDenom}</span>
        )}
      </div>
    </div>
  );
};

interface TemperatureProps {
  temperature: string;
  humidity: string;
}

export const TemperatureWidget: React.FC<TemperatureProps> = ({ temperature, humidity }) => (
  <div className={styles.widget}>
    <div className={styles.temperatureRow}>
      <span className={styles.title}>Temperature, Cº</span>
      <div className={styles.valueRow}>
        <span className={styles.value}>{temperature}</span>
        <ArrowDownRed />
      </div>
    </div>
    <div className={styles.temperatureRow}>
      <span className={styles.title}>Humidity, %</span>
      <div className={styles.valueRow}>
        <span className={styles.value}>{humidity}</span>
        <ArrowUpGreen />
      </div>
    </div>
  </div>
);
