import styles from "./Sidebar.module.css";

interface SidebarProps {
  children: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => (
  <div className={styles.sidebarContainer}>{children}</div>
);

export default Sidebar;
