import { WebPubSubClient } from "@azure/web-pubsub-client";
import { head } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';
import getPrimaryIdByMeshId from '../../helpers/getPrimaryIdByMeshId';
import useNotificationControl from '../../hooks/useNotificationControl';
import { createConnectedClient } from '../../services/notificationService';

function NotificationInitializator({ negotiationApiBaseUrl, token }: { negotiationApiBaseUrl: string, token: string }) {
    const { meshToZoom, menuItems} = useGlobal()
    const clientRef = useRef() as { current: WebPubSubClient }
    const prevMeshToZoom = useRef()
    const {persistNotificationToGlobalStore} = useNotificationControl()

    const primaryTwinId = useMemo(() => {
        const meshObjectIdToZoom = head(meshToZoom) as string

        return getPrimaryIdByMeshId(menuItems, meshObjectIdToZoom)
    }, [menuItems, meshToZoom])

    useEffect(() => {
        async function init() {

            try {
                clientRef.current = await createConnectedClient(negotiationApiBaseUrl, token)
            } catch (error) {
                console.error('Failed to connect to stream web socket', error);
            }

            clientRef.current.on('group-message', args => {
                console.log("Messaging::Group", { args })

                // @ts-ignore
              persistNotificationToGlobalStore(args.message.group, args.message)
            })

            clientRef.current.on('server-message', args => console.log(args.message.data));

            clientRef.current.on('connected', _args => console.log('Connected to stream web socket'));

            clientRef.current.on('disconnected', _args => console.log('Disconnected from stream web socket'));

            await clientRef.current.start();
        }

        init()
    }, [])

    useEffect(() => {

        if (prevMeshToZoom.current && primaryTwinId) {
            if (primaryTwinId !== prevMeshToZoom.current) {
                console.log("Messaging::LeaveGroup", prevMeshToZoom.current)
                clientRef.current.leaveGroup(prevMeshToZoom.current)
            }
        }

        if (primaryTwinId && prevMeshToZoom.current !== primaryTwinId) {
            console.log("Messaging::JoinGroup", primaryTwinId)
            prevMeshToZoom.current = primaryTwinId

            clientRef.current.joinGroup(primaryTwinId)
        }

    }, [primaryTwinId])


    return null

}

export default NotificationInitializator
