import { FC, ReactNode } from 'react';

import styles from './Expandable.module.css';
import { useGlobal } from '../../contexts/GlobalContext';

type ExpandableProps = {
  title: string;
  children: ReactNode;
};

const Expandable: FC<ExpandableProps> = ({ title, children }) => {
  const {expandableSwitch, setExpandable} = useGlobal();

  const toggleExpansion = () => {
    setExpandable({...expandableSwitch, [title]: !expandableSwitch[title]})
   };

  return (
    <div className={styles.root}>
      <span className={`${styles.title} ${expandableSwitch[title] ? styles.expanded : ''}`} onClick={toggleExpansion}>{title}</span>
      <div className={`${styles.content} ${expandableSwitch[title] ? '' : styles.collapsed}`}>{children}</div>
    </div>
  );
};

export default Expandable;
