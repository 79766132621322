const SocketIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 3.99146C16 5.09602 15.1046 5.99146 14 5.99146C13.7734 5.99146 13.5557 5.95379 13.3527 5.88437L12.0688 7.82743C12.64 8.37148 12.9969 9.13844 13 9.98879L14.1703 10.1912C14.4809 9.48955 15.1833 9 16 9C17.1046 9 18 9.89543 18 11C18 12.1046 17.1046 13 16 13C14.9554 13 14.0979 12.1992 14.0078 11.178L12.8378 10.9756C12.59 11.6965 12.0752 12.2933 11.4115 12.6478L11.856 14.0051C11.9036 14.0017 11.9516 14 12 14C13.1046 14 14 14.8954 14 16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16C10 15.2983 10.3614 14.681 10.9082 14.324L10.463 12.9645C10.3121 12.9879 10.1575 13 10 13C9.01183 13 8.13525 12.5222 7.58865 11.7851L5.95411 12.572C5.98416 12.7099 6 12.8531 6 13C6 14.1046 5.10457 15 4 15C2.89543 15 2 14.1046 2 13C2 11.8954 2.89543 11 4 11C4.5986 11 5.13578 11.263 5.5023 11.6797L7.1353 10.8935C7.04737 10.6113 7 10.3112 7 10C7 9.22726 7.29216 8.52275 7.77205 7.99092L6.83676 6.81707C6.58219 6.9345 6.29875 7 6 7C4.89543 7 4 6.10457 4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5C8 5.4434 7.85571 5.8531 7.61152 6.18471L8.55581 7.36985C8.98423 7.13411 9.47645 7 10 7C10.4423 7 10.8622 7.09571 11.2402 7.26753L12.5177 5.33412C12.196 4.97918 12 4.5082 12 3.99146C12 2.88689 12.8954 1.99146 14 1.99146C15.1046 1.99146 16 2.88689 16 3.99146ZM15 3.99146C15 3.43917 14.5523 2.99146 14 2.99146C13.4477 2.99146 13 3.43917 13 3.99146C13 4.54374 13.4477 4.99146 14 4.99146C14.5523 4.99146 15 4.54374 15 3.99146ZM6 6C6.55228 6 7 5.55228 7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5C5 5.55228 5.44772 6 6 6ZM10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12ZM5 13C5 12.4477 4.55228 12 4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14C4.55228 14 5 13.5523 5 13ZM13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16ZM16 12C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12Z"
        fill="#479EF5"
      />
    </svg>
  );
};

export default SocketIcon;
