import { useMemo } from "react";
import { isString } from "lodash";
import routes from "./routes";
import UserAvatar from "../../resources/images/user-avatar.svg";
import styles from "./Navigation.module.css";

const Navigation = () => {
  const renderRoutes = useMemo(
    () =>
      routes({
        name: "Mallory Johnston",
        role: "Facility manager",
        avatar: UserAvatar,
      }).map(({ title, subtitle, icon }, index) => (
        <div key={index} className={styles.routeContainer}>
          {isString(icon) ? <img src={icon} alt="navigation-item" /> : icon()}
          <div className={styles.infoContainer}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
        </div>
      )),
    []
  );

  return <div className={styles.container}>{renderRoutes}</div>;
};

export default Navigation;
