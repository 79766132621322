import React, { useMemo } from "react";

import { motion, AnimatePresence } from "framer-motion";

import Section from "../../section/Section";
import Animation from "../../jarvis/Animation";
import RealNotificationItem from "./RealNotificationItem";

import { INotification } from "../../../ts/general";

import styles from "./index.module.css";

interface NotificationsProps {
  notifications: Array<INotification>;
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

const RealNotificationList: React.FC<NotificationsProps> = ({ notifications }) => {
  const [currentFixed, prevFixed] = useMemo(
    () =>
      notifications.reduce<Array<number>>(
        (acc, { type }, i) =>
          ["emergency", "primary"].includes(type) ? acc.concat(i) : acc,
        []
      ),
    [notifications]
  );

  const modifiedNotifications = useMemo(() => {
    const copy = [...notifications];

    if (currentFixed >= 0 && prevFixed >= 0) {
      const [prevNotification] = copy.splice(prevFixed, 1);
      copy.splice(currentFixed + 1, 0, prevNotification);
    }

    return copy;
  }, [currentFixed, prevFixed]);

  return (
    <div className={styles.notificationsWrapper}>
      <Section label="Scenarios">
        {modifiedNotifications.length > 0 ? (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            className={styles.notificationsContainer}
          >
            <AnimatePresence>
              {modifiedNotifications.map(
                ({ id, type, icon, text, timeUntilNext }, i) => (
                  <RealNotificationItem
                    id={id}
                    icon={icon}
                    text={text}
                    type={type}
                    key={`${id}`}
                    timeUntilNext={timeUntilNext}
                    isFixedType={i === currentFixed}
                  />
                )
              )}
            </AnimatePresence>
          </motion.div>
        ) : (
          <Animation />
        )}
      </Section>
    </div>
  );
};

export default RealNotificationList;
