const EmergencyOnTheWayOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=emergency_on_the_way">
      <path
        id="icon/maps/directions_run_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.55 3.25C16.55 4.35 15.65 5.25 14.55 5.25C13.45 5.25 12.55 4.35 12.55 3.25C12.55 2.15 13.45 1.25 14.55 1.25C15.65 1.25 16.55 2.15 16.55 3.25ZM11.95 14.75L10.95 19.15L3.94995 17.75L4.34995 15.75L9.24995 16.75L10.85 8.65L9.04995 9.35V12.75H7.04995V8.05L12.25 5.85C12.4 5.85 12.525 5.825 12.65 5.8C12.775 5.775 12.9 5.75 13.05 5.75C13.75 5.75 14.35 6.15 14.75 6.75L15.75 8.35C16.55 9.75 18.15 10.75 20.05 10.75V12.75C17.85 12.75 15.85 11.75 14.55 10.25L13.95 13.25L16.05 15.25V22.75H14.05V16.75L11.95 14.75Z"
        fill="white"
      />
      <rect
        id="Rectangle 989"
        x="18"
        y="13"
        width="5"
        height="3"
        fill="white"
      />
    </g>
  </svg>
);

export default EmergencyOnTheWayOutlined;
