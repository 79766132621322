import cn from "classnames";

import styles from "./Delimiter.module.css";

type Props = {
  orientation?: "vertical" | "horizontal";
  color?: string;
  className?: string;
};

const Delimiter: React.FC<Props> = ({
  orientation = "vertical",
  color = "white",
  className = "",
}) => {
  const style = {
    backgroundColor: color,
  };

  return <div className={cn(styles[orientation], className)} style={style} />;
};

export default Delimiter;
