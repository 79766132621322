const { BABYLON } = window;

export function drawAxis({ scene, mesh }) {
  // Create custom axes
  const axesSize = 2; // Adjust the size of the axes as needed
  const xAxis = BABYLON.MeshBuilder.CreateLines(
    "xAxis",
    { points: [BABYLON.Vector3.Zero(), new BABYLON.Vector3(axesSize, 0, 0)] },
    scene
  );
  const yAxis = BABYLON.MeshBuilder.CreateLines(
    "yAxis",
    { points: [BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, axesSize, 0)] },
    scene
  );
  const zAxis = BABYLON.MeshBuilder.CreateLines(
    "zAxis",
    { points: [BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, axesSize)] },
    scene
  );

  // Set the positions of the axes relative to the mesh's local space
  xAxis.position = new BABYLON.Vector3(axesSize / 2, 0, 0);
  yAxis.position = new BABYLON.Vector3(0, axesSize / 2, 0);
  zAxis.position = new BABYLON.Vector3(0, 0, axesSize / 2);

  // Parent the axes to the mesh so they move with it
  xAxis.parent = mesh;
  yAxis.parent = mesh;
  zAxis.parent = mesh;

  // You can also apply custom materials to the axes for better visibility
  xAxis.color = new BABYLON.Color3(1, 0, 0); // Red X-axis
  yAxis.color = new BABYLON.Color3(0, 1, 0); // Green Y-axis
  zAxis.color = new BABYLON.Color3(0, 0, 1); // Blue Z-axis
}

export function createCustomOrigin({ mesh, scene }) {
  const meshBoundingBox = mesh.getBoundingInfo().boundingBox;
  const { vectors } = meshBoundingBox;

  const matrix = mesh.computeWorldMatrix(true); // force calculation of world matrix

  const customMeshOriginCoordinates = BABYLON.Vector3.TransformCoordinates(vectors[2], matrix);

  const customMesh = new BABYLON.Mesh("customMesh", scene);
  customMesh.position = customMeshOriginCoordinates;

  return { origin: customMesh };
}

export function getAllAngels(mesh, scene) {
  if(!mesh?.getBoundingInfo) {
    return
  }
  const meshBoundingBox = mesh.getBoundingInfo()?.boundingBox;
  const meshVectors = meshBoundingBox.vectors;

  const matrix = mesh.computeWorldMatrix(true);

  meshVectors.forEach((vector) => {
    const myPosition = BABYLON.Vector3.TransformCoordinates(vector, matrix);

    const point = BABYLON.MeshBuilder.CreateSphere(
      "sphere",
      { diameter: 1, segments: 32 },
      scene,
      true
    );

    const circleMaterial = new BABYLON.StandardMaterial("circleMaterial", scene);
    circleMaterial.diffuseColor = new BABYLON.Color3(1, 0, 1); // white

    point.position = myPosition;
  });

  const { vectors } = meshBoundingBox;

  const minimum = vectors[2];
  const maximum = vectors[4];

  console.log({ minimum, maximum });

  const pointMax = BABYLON.MeshBuilder.CreateSphere(
    "sphere",
    { diameter: 2, segments: 32 },
    scene,
    true
  );

  const pointMin = BABYLON.MeshBuilder.CreateSphere(
    "sphere",
    { diameter: 2, segments: 32 },
    scene,
    true
  );

  const maxeMaterial = new BABYLON.StandardMaterial("circleMaterial", scene);
  maxeMaterial.diffuseColor = new BABYLON.Color3(0, 1, 1);

  const minMaterial = new BABYLON.StandardMaterial("circleMaterial", scene);
  minMaterial.diffuseColor = new BABYLON.Color3(1, 0, 0);

  const globalPosMax = BABYLON.Vector3.TransformCoordinates(maximum, matrix);
  const globalPosMin = BABYLON.Vector3.TransformCoordinates(minimum, matrix);

  pointMax.position = globalPosMax;
  pointMin.position = globalPosMin;

  pointMax.material = maxeMaterial; // blue
  pointMin.material = minMaterial; // red
}

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);
