import { createContext, ReactNode, useContext, useReducer } from "react";
import merge from "lodash/merge";
import noop from "lodash/noop";
import { reducer } from "./reducer";
import { GlobalContextState } from "../../ts/contexts";
import {
  meshData,
  videosConfig,
  notificationsConfig,
  allowedScenesNameToIdMap,
} from "../../data";
import  { TActuatorItem, TLevel, TSensorItem } from '../../ts/api/index'
import { IActuator, ISensor } from "../../ts/api";
import { TSidebarTree } from '../../components/tree/generateSideBarItems'
import { TNotification } from "../../hooks/useNotificationControl";
import { ExpandableSwitcher, INotification } from "../../ts/general";
import { AxiosResponse } from "axios";

const initialState: GlobalContextState = {
  api: {
    getStatistics: function (): Promise<AxiosResponse<any, any>> {
      throw new Error("Function not implemented.");
    },
    getActuators: function (): Promise<AxiosResponse<TActuatorItem[], any>> {
      throw new Error("Function not implemented.");
    },
    getSensors: function (): Promise<AxiosResponse<TSensorItem[], any>> {
      throw new Error("Function not implemented.");
    },
    getSidebarMenu: function (): Promise<AxiosResponse<any[], any>> {
      throw new Error("Function not implemented.");
    }
  },
    config: {
      bfeBaseUrl: undefined,
      apiAdtProxyBaseUrl: undefined,
      apiBlobStorageProxyBaseUrl: undefined,
      twinToDedicatedViewUrlMapping: {},
      hqBuildingTwinId: "",
      azureConfig: {
      adAuth: {
        authority: null,
        clientId: null,
        scope: null,
        redirectUri: null,
      },
      adtHostUrl: null,
      blobHostUrl: null,
    },
  },
  scenes: [],
  allowedScenesNameToIdMap,
  selectedMeshId: null,
  hoveredMeshId: null,
  meshItems: meshData,
  menuItems: [],
  meshToZoom: [],
  sceneId: null,
  videosConfig,
  notificationsConfig,
  notifications: {},
  realScenarioNotifications: [],
  sensors: {},
  actuators: {},
  primaryTwinId: 'office',
  level: 'building',
  msalConfig: {
    auth: {
      clientId: '',
      authority: '',
      redirectUri: '',
      protocolMode: 'OIDC',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: ''
    }
  },
  popupRequest: {
    scopes: [
      'openid',
      'profile',
      'user.read'
    ]
  },
  floorLevel: 'office',
  expandableSwitch: {
    "Actuators": true,
    "Sensors": true
},
  setSceneId: noop,
  setSelectedMeshId: noop,
  setHoveredMeshId: noop,
  setMenuItems: noop,
  setMeshToZoom: noop,
  setNotification: noop,
  setActuators: noop,
  setSensors: noop,
  setPrimaryTwinId: noop,
  setLevel: noop,
  removeNotification: noop,
  persistRealScenarioNotifications: noop,
  setFloorLevel: noop,
  setExpandable: noop,
};

const GlobalContext = createContext<GlobalContextState>(initialState);

const GlobalProvider = ({
  children,
  defaultState,
}: {
  children: ReactNode;
  defaultState?: Partial<GlobalContextState>;
}) => {
  const [
    {
      api,
      config,
      scenes,
      allowedScenesNameToIdMap,
      selectedMeshId,
      hoveredMeshId,
      meshItems,
      menuItems,
      meshToZoom,
      sceneId,
      videosConfig,
      notificationsConfig,
      notifications,
      sensors,
      actuators,
      primaryTwinId,
      level,
      msalConfig,
      realScenarioNotifications,
      popupRequest,
      floorLevel,
      expandableSwitch,
    },
    dispatch,
  ] = useReducer(reducer, merge(initialState, defaultState));

  const setPrimaryTwinId = (payload: string | null) => {
    dispatch({
      type: "SET_PRIMARY_TWIN_ID",
      payload,
    });
  }

  const setSceneId = (payload: string) => {
    dispatch({
      type: "SET_SCENE_ID",
      payload,
    });
  };

  const setSelectedMeshId = (payload: string | null) => {
    dispatch({
      type: "SET_SELECTED_MESH_ID",
      payload,
    });
  };

  const setHoveredMeshId = (payload: string | null) => {
    dispatch({
      type: "SET_HOVERED_MESH_ID",
      payload,
    });
  };

  const setMenuItems = (payload: TSidebarTree[]) => {
    dispatch({
      type: "SET_MENU_ITEMS",
      payload
    })
  }

  const setMeshToZoom = (payload: string[]) => {
    dispatch({
      type: "SET_MESH_TO_ZOOM",
      payload
    })
  }

  const setNotification = (payload: {
    uuid?: string,
    message: {
      data: TNotification[]
    }
  }) => (
    dispatch({
      type: "SET_NOTIFICATIONS",
      payload
    })
  )

  const setSensors = (payload: { uuid: string, details: ISensor }) => {
    dispatch({
      type: "SET_SENSORS",
      payload
    })
  }

  const setActuators = (payload: { uuid: string, details: IActuator }) => {
    dispatch({
      type: "SET_ACTUATORS",
      payload
    })
  }

  const setLevel = (payload: TLevel) => {
    dispatch({
      type: "SET_LEVEL",
      payload
    })
  }

  const removeNotification = (payload: { uuid: string, id: string }) => {
    dispatch({
      type: "REMOVE_NOTIFICATION",
      payload
    })
  }

  const persistRealScenarioNotifications = (payload: Array<INotification>) => {
    dispatch({
      type: "PERSIST_REAL_SCENARIO_NOTIFICATIONS",
      payload
    })
  }

  const setFloorLevel = (payload: string | null) => {
    dispatch({
      type: "SET_FLOOR_LEVEL",
      payload
    })
  }
 
  const setExpandable = (payload: ExpandableSwitcher | boolean) => {
    dispatch({
      type: "SET_EXPANDABLE_BOOLEAN",
      payload
    })
  }

  return (
    <GlobalContext.Provider
      value={{
        api,
        config,
        scenes,
        allowedScenesNameToIdMap,
        selectedMeshId,
        hoveredMeshId,
        meshToZoom,
        meshItems,
        menuItems,
        sceneId,
        videosConfig,
        notificationsConfig,
        realScenarioNotifications,
        notifications,
        actuators,
        sensors,
        primaryTwinId,
        level,
        msalConfig,
        popupRequest,
        floorLevel,
        expandableSwitch,
        setSceneId,
        setSelectedMeshId,
        setHoveredMeshId,
        setMenuItems,
        setMeshToZoom,
        setNotification,
        setActuators,
        setSensors,
        setPrimaryTwinId,
        setLevel,
        removeNotification,
        persistRealScenarioNotifications,
        setFloorLevel,
        setExpandable,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobal = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error("useGlobal can only be used inside GlobalProvider");
  }

  return context;
};

export { GlobalProvider, useGlobal };
