import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import cn from "classnames";
import isNil from "lodash/isNil";
import times from "lodash/times";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import intersection from "lodash/intersection";
import find from "lodash/find";

import { useGlobal } from "../../contexts/GlobalContext";

import StraightLineIcon from "../icons/branches/straight";
import CaretActiveIcon from "../icons/carets/caret-active";
import CurvatureLineIcon from "../icons/branches/curvature";
import CaretInactiveIcon from "../icons/carets/caret-inactive";
import StraightWithCurvatureLineIcon from "../icons/branches/straight-with-curvature";
import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";

import styles from "./Tree.module.css";
import { TLevel } from "../../ts/api/index";

type MenuItems = {
  node: {
    id: string;
    displayName: string;
    name: string;
    nodes?: Array<MenuItems["node"]>;
    objectIDs?: string[],
    primaryTwinID?: string,
    level?: TLevel,
  };
  isLast: boolean;
  level?: number;
  defaultOpen?: boolean;
  parentSceneId?: string;
  parentName?: string;
  primaryTwinId?: string;
  nodeLevel?: string;
};

const tick = (cb: () => void) => setTimeout(cb, 200)

const ChildNode = ({
                     parentName,
                     node,
                     node: { displayName, objectIDs, primaryTwinID, level: nodeLevel },
                     isLast,
                     level = 1
                   }: MenuItems) => {
  const {
    setMeshToZoom,
    videosConfig,
    meshToZoom,
    setHoveredMeshId,
    sceneId,
    setSceneId,
    scenes,
    setPrimaryTwinId,
    setLevel,
    setFloorLevel
  } = useGlobal();
  const smoothNavigate = useNavigateWithTransition(useNavigate());
  
  const onClickElement = useCallback(() => {
    const meshId: string = objectIDs?.[0] ?? "";
    // set floor lv for filtering hardware devices
    setFloorLevel(primaryTwinID as any);
    // If the same Mesh, do nothing
    if (isEqual(meshToZoom, objectIDs)) {
      return;
    }
    
    // (style): select mesh in Videobar
    setHoveredMeshId(meshId as string);
    
    const meshScene = find(scenes, { name: parentName });
    
    // Change scene acroding to selected Mesh
    if (sceneId !== meshScene?.id) {
      setSceneId(meshScene?.id as string);
    }
    
    if (meshId === "Floor6 Enterprise" || meshId === "Wall Mesh.002") {
      // Note: for demo purpose
      // 2DO - solve camera reset after swiching to video and slow bandwith
      // added setTimeout to reset the camera zooming after switching floors
      setMeshToZoom([]);
      
      tick(() => setMeshToZoom([meshId]))
      smoothNavigate(`/`);
      // Note: for demo purpose. Remove "Floor6 Atomic" from the video
    } else if (meshId in videosConfig) {
      smoothNavigate(`/scenario/${meshId}`);
      setMeshToZoom([]);
      
      tick(() => setMeshToZoom([meshId]))
    } else {
      smoothNavigate(`/`);
      setMeshToZoom([]);
      
      tick(() => setMeshToZoom([meshId]))
    }
    
    setPrimaryTwinId(primaryTwinID as string);
    setLevel(nodeLevel as any);
  }, [meshToZoom]);
  
  return (
    <li className={styles.node}>
      <div className={styles.labelContainer}>
        {times(level, (n) => (
          <div className={styles.branch}>
            {n === level - 1 ? (
              isLast ? (
                <CurvatureLineIcon />
              ) : (
                <StraightWithCurvatureLineIcon />
              )
            ) : (
              <StraightLineIcon />
            )}
          </div>
        ))}
        <span
          onClick={onClickElement}
          className={cn(styles.label, {
            [styles.labelOpen]: intersection(meshToZoom, objectIDs).length > 0
          })}
        >
          {displayName}
        </span>
      </div>
    </li>
  );
};

const Node = ({
                node: { id, name, displayName, nodes, primaryTwinID, level: nodeLevel },
                isLast,
                level = 1,
                defaultOpen = false,
                ...props
              }: MenuItems) => {
  const {
    allowedScenesNameToIdMap,
    sceneId,
    setSceneId,
    setHoveredMeshId,
    setMeshToZoom,
    setPrimaryTwinId,
    setLevel,
    setFloorLevel
  } = useGlobal();
  const smoothNavigate = useNavigateWithTransition(useNavigate());
  
  const [isOpen, setIsOpen] = useState(defaultOpen);
  
  const hasNodes = !isNil(nodes) && !isEmpty(nodes);
  const allowedSceneId = allowedScenesNameToIdMap[name];
  
  const onNodeClick = useCallback((id: string) => {
    // set floor lv for filtering hardware devices
    setFloorLevel(primaryTwinID as string);
    const computedLevel = props.nodeLevel === 'office' ? 'buildings' : props.nodeLevel
    setLevel(computedLevel || nodeLevel as any)
    
    if (!allowedSceneId) {
      return;
    }
    
    setMeshToZoom([]);
    setHoveredMeshId("");
    smoothNavigate("/");
    
    if (hasNodes) {
      setIsOpen((prevOpen) => !prevOpen);
    }
    
    if (allowedSceneId) {
      setSceneId(allowedSceneId);
    }
    
    // if (id === "d754039ca0614b8d8e4deccc1ae9f285") {
    //   setPrimaryTwinId("office");
    //   // setLevel('' as any);
    // } else {
    //
    // }
    
    if(nodeLevel) {
      setPrimaryTwinId(props.primaryTwinId as string);
    } else {
      // setPrimaryTwinId(primaryTwinID as string);
      setPrimaryTwinId("office");
    }
    
  }, [hasNodes, allowedSceneId, primaryTwinID, setSceneId, setPrimaryTwinId, setLevel, level]);
  
  return (
    <li className={styles.node}>
      <div className={styles.labelContainer}>
        {times(level, (n) => (
          <div className={styles.branch}>
            {n === level - 1 ? (
              isLast ? (
                <CurvatureLineIcon />
              ) : (
                <StraightWithCurvatureLineIcon />
              )
            ) : (
              <StraightLineIcon />
            )}
          </div>
        ))}
        {hasNodes && (isOpen ? <CaretActiveIcon /> : <CaretInactiveIcon />)}
        <span
          onClick={() => onNodeClick(id)}
          className={cn(styles.label, {
            [styles.labelOpen]: allowedSceneId === sceneId,
            [styles.disabled]: !allowedSceneId
          })}
        >
          {displayName}
        </span>
      </div>
      {isOpen &&
        nodes?.map((node, index) => (
          <ul key={`node-${index}`} className={styles.tree}>
            <ChildNode parentName={name} node={node} level={level + 1} isLast={nodes.length - 1 === index} />
          </ul>
        ))}
    </li>
  );
};


export default Node;
