const Administrator = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 18.5H20V5.5H19.7L15 7.2V8.9L18 7.88V18.5ZM8 7.5H10V11.5H14V13.5H10V17.5H8V13.5H4V11.5H8V7.5Z"
      fill="white"
    />
  </svg>
);

export default Administrator;
