const LeaveAFeedbackIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 12C11.3284 12 12 12.6716 12 13.5V14C12 15.9714 10.1405 18 7 18C3.85951 18 2 15.9714 2 14V13.5C2 12.6716 2.67157 12 3.5 12H10.5ZM10.5 13H3.5C3.22386 13 3 13.2239 3 13.5V14C3 15.4376 4.43216 17 7 17C9.56784 17 11 15.4376 11 14V13.5C11 13.2239 10.7761 13 10.5 13ZM7 5.5C8.51878 5.5 9.75 6.73122 9.75 8.25C9.75 9.76878 8.51878 11 7 11C5.48122 11 4.25 9.76878 4.25 8.25C4.25 6.73122 5.48122 5.5 7 5.5ZM16 2C17.0544 2 17.9182 2.81588 17.9945 3.85074L18 4V6C18 7.05436 17.1841 7.91817 16.1493 7.99451L16 8H14.499L13.301 9.59979C12.7716 10.306 11.6965 10.0199 11.5243 9.22426L11.5066 9.11264L11.5008 9L11.5 7.935L11.4224 7.91532C10.697 7.69685 10.1452 7.07753 10.0246 6.3148L10.0055 6.14926L10 6V4C10 2.94564 10.8159 2.08183 11.8507 2.00549L12 2H16ZM7 6.5C6.0335 6.5 5.25 7.2835 5.25 8.25C5.25 9.2165 6.0335 10 7 10C7.9665 10 8.75 9.2165 8.75 8.25C8.75 7.2835 7.9665 6.5 7 6.5ZM16 3H12C11.4872 3 11.0645 3.38604 11.0067 3.88338L11 4V6C11 6.51284 11.386 6.93551 11.8834 6.99327L12 7H12.5008V9L14 7H16C16.5128 7 16.9355 6.61396 16.9933 6.11662L17 6V4C17 3.48716 16.614 3.06449 16.1166 3.00673L16 3Z"
        fill="#D6D6D6"
      />
    </svg>
  );
};

export default LeaveAFeedbackIcon;
