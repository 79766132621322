import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";
import { useGlobal } from "../../contexts/GlobalContext";
import { Overlay } from "./Video";
import { useHover } from "../../hooks";

import WHEPClient, { cleanStream  } from "../../helpers/WHEPClient";

import styles from './VideoBar.module.css'

const RealTimeVideo = ({ src, id, labels, isActive, poster }: any) => {

  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const smoothNavigate = useNavigateWithTransition(navigate);
  const { setMeshToZoom, setHoveredMeshId } = useGlobal()
  const to = `/scenario/${id}`;
  const {setExpandable} = useGlobal()
  const onNavigate = () => {
    // Reset actuator and sensors collapse
    setExpandable(false)
    smoothNavigate(to);
    setHoveredMeshId(id)
    setMeshToZoom([id])
  }; 
 
  useEffect(() => {
    const initWHEPClient = (video: null, transformFn: any, streamUrl: any) => new WHEPClient(videoRef.current, cleanStream(), streamUrl);
    const transfromFn = cleanStream();
     initWHEPClient(
      videoRef.current as any,
      transfromFn,
      src,
    )

  }, [src])

  const [hoverRef, isHovered] = useHover();

  const isFocused = isActive || isHovered;

  return (
    <div
      ref={hoverRef}
      onClick={onNavigate}
      style={{ zIndex: 2, cursor: "pointer" }}
      className={styles.videoBox}
    >
      <div
        className={styles.videoContainer}
      >
        <div className={cn(styles.videoPlaceholder, {[styles.active]: isFocused})}>
          <video className={styles.realtime} /* ref={videoRef} */ id="video" autoPlay playsInline muted poster={poster}></video>
          <Overlay labels={labels} displayPlayButton={true} />
        </div>
      </div>
    </div>
  );
}

export default RealTimeVideo
