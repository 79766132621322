// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TWhepClient } from "./ts/general";

const restartPause = 2000;
function getRandomStringWithTimeout() {
    const randomString10 = Math.random().toString(36).substring(2, 15);
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(randomString10);
        }, 1000);
    });
}

function showText({
    txtInitialX,
    txtColor = '#08b9a6',
    txtFontSize = '25px',
    txtFont = 'serif',
    textSpeed = 2,
    bgColor = 'transparent',
    bgPadding = 10,
    position = 'top'
}) {
    const canvas = new OffscreenCanvas(1, 1);
    const ctx = canvas.getContext('2d');
    const intTxtFontSize = parseInt(txtFontSize);

    let x = txtInitialX;

    return function transform(frame, controller, text) {
        // videoframe + canvas -> new videoframe -> return frame
        // const { timestamp } = frame;
        // console.log(new Date(timestamp + text));
        // text ->
        const width = frame.displayWidth;
        const height = frame.displayHeight;
        canvas.width = width;
        canvas.height = height;

        const bgHeight = intTxtFontSize + bgPadding;
        const bgPositionY = position === 'bottom'
            ? height - (intTxtFontSize + bgPadding + 5)
            : 5;
        const txtPositionY = position === 'bottom'
            ? height - (Math.floor(bgPadding / 2) + 1)
            : 20 + intTxtFontSize;

        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(frame, 0, 0, width, height);
        ctx.font = txtFontSize + ' ' + txtFont;
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, bgPositionY, width, bgHeight)
        ctx.fillStyle = txtColor;
        ctx.fillText(new Date(text), x, txtPositionY);

        const newFrame = new VideoFrame(canvas, { timestamp: frame.timestamp });

        frame.close();
        controller.enqueue(newFrame);
    }
}

const unquoteCredential = (v) => (
    JSON.parse(`"${v}"`)
);

const linkToIceServers = (links) => (
    (links !== null) ? links.split(', ').map((link) => {
        const m = link.match(/^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i);
        const ret = {
            urls: [m[1]],
        };

        if (m[3] !== undefined) {
            ret.username = unquoteCredential(m[3]);
            ret.credential = unquoteCredential(m[4]);
            ret.credentialType = "password";
        }

        return ret;
    }) : []
);

const parseOffer = (offer) => {
    const ret = {
        iceUfrag: '',
        icePwd: '',
        medias: [],
    };

    for (const line of offer.split('\r\n')) {
        if (line.startsWith('m=')) {
            // ret.medias.push(line.slice('m='.length));
        } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
            ret.iceUfrag = line.slice('a=ice-ufrag:'.length);
        } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
            ret.icePwd = line.slice('a=ice-pwd:'.length);
        }
    }

    return ret;
};

const enableStereoOpus = (section) => {
    let opusPayloadFormat = '';
    let lines = section.split('\r\n');

    for (let i = 0; i < lines.length; i++) {
        if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
            opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0];
            break;
        }
    }

    if (opusPayloadFormat === '') {
        return section;
    }

    for (let i = 0; i < lines.length; i++) {
        if (lines[i].startsWith('a=fmtp:' + opusPayloadFormat + ' ')) {
            if (!lines[i].includes('stereo')) {
                lines[i] += ';stereo=1';
            }
            if (!lines[i].includes('sprop-stereo')) {
                lines[i] += ';sprop-stereo=1';
            }
        }
    }

    return lines.join('\r\n');
};

const editOffer = (offer) => {
    const sections = offer.sdp.split('m=');

    for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (section.startsWith('audio')) {
            sections[i] = enableStereoOpus(section);
        }
    }

    offer.sdp = sections.join('m=');
};

const generateSdpFragment = (offerData, candidates) => {
    const candidatesByMedia = {};
    for (const candidate of candidates) {
        const mid = candidate.sdpMLineIndex;
        if (candidatesByMedia[mid] === undefined) {
            candidatesByMedia[mid] = [];
        }
        candidatesByMedia[mid].push(candidate);
    }

    let frag = 'a=ice-ufrag:' + offerData.iceUfrag + '\r\n'
        + 'a=ice-pwd:' + offerData.icePwd + '\r\n';

    let mid = 0;

    for (const media of offerData.medias) {
        if (candidatesByMedia[mid] !== undefined) {
            frag += 'm=' + media + '\r\n'
                + 'a=mid:' + mid + '\r\n';

            for (const candidate of candidatesByMedia[mid]) {
                frag += 'a=' + candidate.candidate + '\r\n';
            }
        }
        mid++;
    }

    return frag;
}

function createProcessedTrack({ track, transform }) {
    const trackProcessor = new MediaStreamTrackProcessor({ track });
    const trackGenerator = new MediaStreamTrackGenerator({ kind: track.kind }); // video

    const transformer = new TransformStream({ transform });

    trackProcessor.readable
        .pipeThrough(transformer)
        .pipeTo(trackGenerator.writable);

    return trackGenerator;
}

export function cleanStream() {
    return function transform(frame, controller, data) {
        controller.enqueue(frame);
    }
}

// example of data for bounding box:
// {"timestamp": "2024-01-17T18:37:30.987Z", "debug_timestamp_end": "2024-01-17T18:37:31.304Z", "debug_processing_time": 0.3165574073791504, "debug_mac": "02:42:c0:a8:00:0a", "id": "static_cam0", "objects": [{"id": 1, "category": "person", "confidence": 0.9821856617927551, "bounding_box": {"x": 0.40110497237569065, "y": -0.17790055248618786, "width": 0.2983425414364641, "height": 0.5701657458563536}, "center_of_mass": {"x": 1093, "y": 328, "width": 90.0, "height": 129.0}, "bounding_box_px": {"x": 1003, "y": 199, "width": 270.0, "height": 516.0}}], "rate": 2.6, "intrinsics": {"fx": 905.0, "fy": 905.0, "cx": 640.0, "cy": 360.0}}

export function boundingBox() {
    const color = '#08b9a6';
    const lineWidth = 2;
    const canvas = new OffscreenCanvas(1, 1);
    const ctx = canvas.getContext('2d');
    return function transform(frame, controller, data, showOverlay) {
        if (!showOverlay) {
            controller.enqueue(frame);
            return;
        }

        const width = frame.displayWidth;
        const height = frame.displayHeight;
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(frame, 0, 0, width, height);

        const videoWidth = JSON.parse(data)?.intrinsics?.cx;
        const videoHeight = JSON.parse(data)?.intrinsics?.cy;

        // scale 640x360 to videoWidth x videoHeight
        // 640/
        const xScale = width / videoWidth;
        const yScale = height / videoHeight;
        // convert 1280x720 coordinates to 640x360 coordinates
        ctx.scale(xScale, yScale);

        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        const objects = JSON.parse(data)?.objects || [];
        for (const object of objects) {
            const { bounding_box_px } = object;
            const { x, y, width, height } = bounding_box_px;
            ctx.strokeRect(x, y, width, height);
        }

        // add an indication that stream is drawing overlay in the top right corner
        // ctx.font = '20px serif';
        // ctx.fillStyle = color;
        // ctx.fillText('overlay', 10, 20);

        const newFrame = new VideoFrame(canvas, { timestamp: frame.timestamp });

        frame.close();
        controller.enqueue(newFrame);
    }
}
class WHEPClient implements TWhepClient {
    pc: RTCPeerConnection;
    constructor(video, transformFn?, streamUrl) {
        this.video = video;
        this.transformFn = transformFn;
        this.streamUrl = streamUrl;
        this.pc = null;
        this.restartTimeout = null;
        this.sessionUrl = '';
        this.queuedCandidates = [];
        this.stream = null;
        this.data = null; 
        this.showOverlay = false;
        this.baseTimestamp = null;
        this.start();
        document.addEventListener('message-received', (evt) => {
            this.data = evt.detail;
        })
        document.addEventListener('toggle-overlay', evt => {
            this.showOverlay = evt.detail;
        })
    }start() {
        fetch(new URL('whep', this.streamUrl) + window.location.search, {
            method: 'OPTIONS',
        })
            .then((res) => this.onIceServers(res))
            .catch((err) => {
                console.log('error: ' + err);
                this.scheduleRestart();
            });
    }

    getVideoStream() {
        return this.video.srcObject;
    }

    onIceServers(res) {
        this.pc = new RTCPeerConnection({
            iceServers: linkToIceServers(res.headers.get('Link')),
            // https://webrtc.org/getting-started/unified-plan-transition-guide
            sdpSemantics: 'unified-plan',
        });


        this.pc.getStats().then((stats) => {
            stats.forEach((report) => {
                this.baseTimestamp = report.timestamp;
            });
        });


        const direction = "sendrecv";
        this.pc.addTransceiver("video", { direction });
        // this.pc.addTransceiver("audio", { direction });
        this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt);
        this.pc.oniceconnectionstatechange = () => this.onConnectionState();

        this.pc.ontrack = (evt) => {
            console.log('on track');
            this.ontrackCallback(evt);
            // this.video.srcObject = evt.streams[0];
        };

        this.pc.createOffer()
            .then((offer) => this.onLocalOffer(offer));
    }

    onLocalOffer(offer) {
        editOffer(offer);

        this.offerData = parseOffer(offer.sdp);
        this.pc.setLocalDescription(offer);

        console.log("sending offer");
        
        fetch(new URL('whep', this.streamUrl) + window.location.search, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/sdp',
            },
            body: offer.sdp,
        })
            .then((res) => {
                if (res.status !== 201) {
                    throw new Error('bad status code');
                }
                this.sessionUrl = new URL(res.headers.get('location'), this.streamUrl).toString();
                return res.text();
            })
            .then((sdp) => this.onRemoteAnswer(new RTCSessionDescription({
                type: 'answer',
                sdp,
            })))
            .catch((err) => {
                console.log('error: ' + err);
                this.scheduleRestart();
            });
    }

    onConnectionState() {
        if (this.restartTimeout !== null) {
            return;
        }

        switch (this.pc.iceConnectionState) {
            case "disconnected":
                this.scheduleRestart();
        }
    }

    async ontrackCallback(evt) {
        console.log('ontrackCallback', this.data);
        const processedTrack = createProcessedTrack({
            track: evt.track,
            transform: (frame, controller) => this.transformFn(frame, controller, this.data, this.showOverlay),
        });
        // Create a new MediaStream containing the processed track
        const processedStream = new MediaStream([processedTrack]);
        // Set the processed stream as the video source
        this.video.srcObject = processedStream;
    }

    onRemoteAnswer(answer) {
        if (this.restartTimeout !== null) {
            return;
        }

        this.pc.setRemoteDescription(answer);

        if (this.queuedCandidates.length !== 0) {
            this.sendLocalCandidates(this.queuedCandidates);
            this.queuedCandidates = [];
        }
    }

    onLocalCandidate(evt) {
        if (this.restartTimeout !== null) {
            return;
        }

        if (evt.candidate !== null) {
            if (this.sessionUrl === '') {
                this.queuedCandidates.push(evt.candidate);
            } else {
                this.sendLocalCandidates([evt.candidate])
            }
        }
    }

    sendLocalCandidates(candidates) {
        fetch(this.sessionUrl + window.location.search, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/trickle-ice-sdpfrag',
                'If-Match': '*',
            },
            body: generateSdpFragment(this.offerData, candidates),
        })
            .then((res) => {
                if (res.status !== 204) {
                    throw new Error('bad status code');
                }
            })
            .catch((err) => {
                console.log('error: ' + err);
                this.scheduleRestart();
            });
    }

    scheduleRestart() {
        if (this.restartTimeout !== null) {
            return;
        }

        if (this.pc !== null) {
            this.pc.close();
            this.pc = null;
        }

        this.restartTimeout = window.setTimeout(() => {
            this.restartTimeout = null;
            this.start();
        }, restartPause);

        if (this.sessionUrl) {
            fetch(this.sessionUrl, {
                method: 'DELETE',
            })
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error('bad status code');
                    }
                })
                .catch((err) => {
                    console.log('delete session error: ' + err);
                });
        }
        this.sessionUrl = '';

        this.queuedCandidates = [];
    }
}

export default WHEPClient;