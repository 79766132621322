import {FC, ReactElement, useState} from 'react';

import {IDeviceNew} from '../../ts/general';

import styles from './HardwareTable.module.css';
import {IActuator, ISensor} from '../../ts/api';
import {hardwareMap} from "../../constants";
import {getHardwareIcon} from '../notifications/useNotificationSelector';
import {useGlobal} from '../../contexts/GlobalContext';

interface HardwareTableProps {
  actuatorsList: IActuator[];
  sensorsList: ISensor[];
}

enum ETabs {
  allDevices = 'allDevices',
  actuators = 'actuators',
  sensors = 'sensors',
}

interface IDevicesMap {
  [ETabs.allDevices]: IDeviceNew[];
  [ETabs.actuators]: IActuator[];
  [ETabs.sensors]: ISensor[];
}

const tabLabels: { [key in ETabs]: string } = {
  [ETabs.allDevices]: 'All Devices',
  [ETabs.actuators]: 'Actuators',
  [ETabs.sensors]: 'Sensors',
};

export const HardwareTable: FC<HardwareTableProps> = ({actuatorsList, sensorsList}): ReactElement => {
  const [activeTab, setActiveTab] = useState<ETabs>(ETabs.allDevices);
  const {floorLevel} = useGlobal()
  //2DO filter for People locations filter, rerendering on change floor till modal is open
  const devices: IDevicesMap = {
    [ETabs.allDevices]: [...actuatorsList, ...sensorsList].filter(device => floorLevel !== 'office' ? device.id.includes(floorLevel as string) : device),
    [ETabs.actuators]: actuatorsList.filter((actuator) => floorLevel !== 'office' ? actuator.id.includes(floorLevel as string) : actuator),
    [ETabs.sensors]: sensorsList.filter(sensor => floorLevel !== 'office' ? sensor.id.includes(floorLevel as string) : sensor),
  };
  
  const handleSelectTab = (tab: ETabs) => () => {
    setActiveTab(tab);
  };
  
  return (
    <div className={styles.root}>
      <div className={styles.tabs}>
        {Object.values(ETabs).map((tab) => (
          <div
            key={tab}
            className={`${styles.tab} ${activeTab === tab ? styles.active : ''}`}
            onClick={handleSelectTab(tab as ETabs)}
          >
            {tabLabels[tab as ETabs]}
          </div>
        ))}
      </div>
      <div className={styles.tableContainer}>
        <table>
          <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Priority</th>
            <th>Type</th>
            <th>Status</th>
            <th>ID</th>
            <th>Placement</th>
            <th>Last Action</th>
          </tr>
          </thead>
          <tbody>
          {devices[activeTab].map(TableDevice)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function TableDevice(device: IDeviceNew, index: number) {
  const {name, priority, type, lastAction, isOnline, id, deviceTypeCode, displayLocation} = device;
  
  const status = isOnline ? 'active' : 'inactive';
  const label = hardwareMap[name]?.label || name;
  const absolutePlacementLocation = (displayLocation ?? []).join(' / ')
  
  return (
    <tr key={index}>
      <td>
        <div className={`${styles.icon} ${styles[`border-${status}`]}`}>{getHardwareIcon(deviceTypeCode)}</div>
      </td>
      <td>{label}</td>
      <td>{priority}</td>
      <td className={styles.capitalized}>{type.toLowerCase()}</td>
      <td className={`${styles.capitalized} ${styles[`text-${status}`]}`}>{status}</td>
      <td>
        <span className={styles.identifier}>{id}</span>
      </td>
      <td>{absolutePlacementLocation}</td>
      <td>{lastAction}</td>
    </tr>
  );
}
