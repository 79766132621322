import "@microsoft/iot-cardboard-js/themes.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Theme,
  ADT3DViewer,
  MsalAuthService,
  ViewerObjectStyle,
  ADT3DSceneAdapter,
  ADT3DAddInEventData,
} from "@microsoft/iot-cardboard-js";

import {
  I3DScenesConfig,
  ITwinToObjectMapping,
} from "@microsoft/iot-cardboard-js/SchemaTypes";
import some from "lodash/some";
import isNil from "lodash/isNil";

import useMotionDrawer from "./useMotionDrawer";

import { useGlobal } from "../../contexts/GlobalContext";
import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";

import styles from "./ADT3DViewerCard.module.css";
import "./ADT3DViewer.css";


const objectColor = {
  color: "#e3d072",
  baseColor: "#e4b7691A",
  lightingStyle: 1,
  coloredMeshColor: "#2087D6FF",
  meshHoverColor: "#8BC1E9FF",
  coloredMeshHoverColor: "#4AA7EEFF",
  outlinedMeshHoverColor: "#23FFFFFF",
  outlinedMeshSelectedColor: "#3595DEFF",
  outlinedMeshHoverSelectedColor: "#12C7E6FF",
};

export const ADT3DViewerCard = () => {
  const {
    config: {
      azureConfig,
      apiAdtProxyBaseUrl,
      apiBlobStorageProxyBaseUrl,
      twinToDedicatedViewUrlMapping,
    },
    sceneId,
    meshItems,
    meshToZoom,
    hoveredMeshId,
    selectedMeshId,
    videosConfig,
    allowedScenesNameToIdMap,
    setSceneId,
    setHoveredMeshId,
    setSelectedMeshId,
  } = useGlobal();
  const smoothNavigate = useNavigateWithTransition(useNavigate());
  const { persistScene } = useMotionDrawer();

  // START: Set up adapter and use it to get the scenes config
  const [adapter, setAdapter] = useState<ADT3DSceneAdapter>(
    null as unknown as ADT3DSceneAdapter
  );
  const [scenesConfig, setScenesConfig] = useState<I3DScenesConfig>(
    null as unknown as I3DScenesConfig
  );

  useEffect(() => {
    if (
      some(azureConfig.adAuth, isNil) ||
      isNil(azureConfig.adtHostUrl) ||
      isNil(azureConfig.blobHostUrl)
    ) {
      return;
    }

    const adapter = new ADT3DSceneAdapter(
      // @ts-ignore
      new MsalAuthService(azureConfig.adAuth),
      azureConfig.adtHostUrl,
      azureConfig.blobHostUrl,
      "",
      "",
      apiAdtProxyBaseUrl,
      apiBlobStorageProxyBaseUrl,
      true,
      true
    );

    adapter
      .getScenesConfig()
      .then(({ result: { data } }) => data)
      .then((config) => {
        setScenesConfig(config);
      });

    setAdapter(adapter);
  }, []);

  const onMeshClick = (eventData: ADT3DAddInEventData) => {
    const {
      mesh: { id: meshId },
      config,
    } = eventData;

    const isMeshItem = some(meshItems, (item) => item.meshId === meshId);

    if (!selectedMeshId && isMeshItem) {
      setSelectedMeshId(meshId);
    } else {
      setSelectedMeshId(null);
    }

    const sceneIndex = config.configuration.scenes.findIndex(
      (s) => s.id === sceneId
    );
    const scene = config.configuration.scenes[sceneIndex];

    const isTwinMapping = (element: {
      type: string;
    }): element is ITwinToObjectMapping =>
      element.type === "TwinToObjectMapping";
    const findMatchingMapping = ({ objectIDs }: ITwinToObjectMapping) =>
      objectIDs.includes(meshId);

    const twinMappings = scene.elements.filter(isTwinMapping);
    const matchingMapping = twinMappings.find(findMatchingMapping);

    if (matchingMapping) {
      const targetScenePath =
        twinToDedicatedViewUrlMapping[matchingMapping.primaryTwinID];

      if (targetScenePath) {
        const newSceneId = allowedScenesNameToIdMap?.[targetScenePath];

        if (newSceneId) {
          setSceneId(newSceneId);
          return true;
        }
      } else if (meshId in videosConfig && meshId !== "Floor6 Enterprise") {
        smoothNavigate(`/scenario/${meshId}`);
        setSelectedMeshId(null);
        return true;
      }
    }

    return false;
  };

  const onMeshHover = (eventData: ADT3DAddInEventData) => {
    const {
      mesh: { id: meshId },
    } = eventData;

    if (!hoveredMeshId && some(meshItems, (item) => item.meshId === meshId)) {
      setHoveredMeshId(meshId);
    } else {
      setHoveredMeshId(null);
    }

    return false;
  };

  return !scenesConfig || !sceneId ? (
    <div></div>
  ) : (
    <div className={styles.card}>
      <ADT3DViewer
        sceneId={sceneId}
        scenesConfig={scenesConfig}
        adapter={adapter}
        coloredMeshItems={meshItems}
        sceneViewProps={{
          // This makes 3d model transparent right on first load. There can be a better way to do it, for instance resolve preconfigured view theme and take its setting.
          objectStyle: ViewerObjectStyle.Transparent,
          objectColor: objectColor,
          showHoverOnSelected: true,
          showMeshesOnHover: false,
          zoomToMeshIds: meshToZoom,
          // @ts-ignore
          onSceneLoaded: persistScene,
        }}
        theme={Theme.Explorer}
        addInProps={{
          onMeshClick,
          onMeshHover,
        }}
        hideViewModePickerUI
        hideElementsPanel
        containerClassName={styles.viewer}
      />
    </div>
  );
};
