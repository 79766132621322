import {ReactNode} from "react";
import cn from 'classnames'

import Skeleton from 'react-loading-skeleton'
import {getHardwareIcon} from "../notifications/useNotificationSelector";

import Delimiter from "../delimiter/Delimiter";
import {hardwareMap} from "../../constants";


import 'react-loading-skeleton/dist/skeleton.css'
import styles from "./Hardware.module.css";
import { useGlobal } from "../../contexts/GlobalContext";
import isEmpty from "lodash/isEmpty";

function getHardwareStateModifiers(deviceTypeCode: string, value: string, isOnline: boolean) {
  let text;
  let cssClass;
  let ignored = false;
 
  
  switch (deviceTypeCode) {
    
    case "CO2" : {
      const numberValue = Number(value);
      
      if(numberValue >= 250 || numberValue <= 400) {
        text = 'Optimal'
        cssClass = styles.green
      }
      if(numberValue < 250 ){
        text = 'Below normal'
        cssClass = styles.yellow
      }
     
      if(numberValue >= 400) {
        text = 'Above normal'
        cssClass = styles.yellow
      }


      break;
    }
    
    case "HUMIDITY" : {
      const numberValue = Number(value);

      if(numberValue >= 40 || numberValue <= 60) {
        text = "Optimal"
        cssClass = styles.green
      }

      if(numberValue < 40) {
        text = "Below normal"
        cssClass = styles.yellow
      }

      if(numberValue > 60 ) {
        text = "Above normal"
        cssClass = styles.yellow
      }

      break;
    }
    
    case "TEMPERATURE": {
      const numberValue = Number(value);

      if(numberValue >= 20 || numberValue <= 22) {
        text = 'Optimal'
        cssClass = styles.green
      }
      if(numberValue < 18) {
        text = 'Below optimal'
        cssClass = styles.yellow
      }
      
      
      if(numberValue > 22) {
        text = 'Above normal'
        cssClass = styles.yellow
      }
      
    
      break;
    }
    
    default:
      ignored = true;
      break;
  }
  
  return {
    text: isOnline ? text : "No data",
    cssClass: isOnline ? cssClass : '',
    ignored
  }
}


type Props = {
  icon?: () => ReactNode;
  name: string;
  priority: string;
  isOnline?: boolean;
  isActive?: boolean;
  value: string;
  deviceTypeCode: string;
  displayLocation?: string[];
};


export const DeviceSkeleton = () => {
  return (
    <div className={styles.device}>
      <Skeleton baseColor='#1a235b' circle={true} height={40} width={40}/>
      <Delimiter className={styles.delimiter} color="#7588bd"/>
      <div className={styles.text}>
        <span className={styles.name}>
          <Skeleton baseColor='#1a235b' width={70}/>
        </span>
        <div className={`${styles.status} ${styles['text-unknown']}`}>
          <span>
            <Skeleton baseColor='#1a235b' width={40}/>
          </span>
        </div>
      </div>
      <div className={styles.priority}>
        <span>
          <Skeleton baseColor='#1a235b' width={12}/>
        </span>
      </div>
    </div>
  );
}

const Device = ({name, isOnline, isActive, priority, value, deviceTypeCode, displayLocation}: Props) => {
  const { meshToZoom} = useGlobal()
  const status = isOnline ? 'active' : 'inactive';
  const label = hardwareMap[name]?.label || name;
  
  const {text, cssClass, ignored} = getHardwareStateModifiers(deviceTypeCode, value, isOnline as boolean)
  const deviceLocation = displayLocation && isEmpty(meshToZoom) && displayLocation.join(' / ')
  
  return (
    <div className={styles.device}>
      
      <div className={`${styles.icon} ${styles[`border-${status}`]}`}>{getHardwareIcon(deviceTypeCode)}</div>
      <Delimiter className={styles.delimiter} color="#7588bd"/>
      
      <div className={styles.text}>
        
        <div className={styles.nameContainer}>
          
          <div>
            <span className={styles.name}>{label}</span>
            {deviceLocation && (
              <div>
                <span className={styles.devLocation}>{deviceLocation}</span>
              </div>
            )}
          </div>
          
        </div>
        
        <div className={`${styles.status} ${styles[`text-${status}`]}`}>
          <div>
            <span>{status}</span>
          </div>
          {!ignored && (<div>
            <span className={cn(styles.statusHardware, cssClass)}>{text}</span>
          </div>)}
        </div>
      </div>
      
      <div className={styles.priority}>
        <span>{priority}</span>
      </div>
      
    </div>
  );
};

export default Device;
