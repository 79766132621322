import React, { useEffect } from "react";

import cn from "classnames";
import { useNavigate } from "react-router-dom";

import { TVideoMainPage, TLabels } from "../../ts/general";
import { useHover } from "../../hooks";
import PlayIcon from "../icons/play";

import styles from "./VideoBar.module.css";
import useNavigateWithTransition from "../../hooks/useNavigateWithTransition";
import { useGlobal } from "../../contexts/GlobalContext";
import getPrimaryIdByMeshId from "../../helpers/getPrimaryIdByMeshId";

export interface IVideoProps extends TVideoMainPage {
  id: string;
  isActive: boolean;
  poster?: string;
}

const Video: React.FC<IVideoProps> = ({ id, isActive, src, labels, poster }) => {
  const navigate = useNavigate();
  const smoothNavigate = useNavigateWithTransition(navigate);
  const { setSceneId, setMeshToZoom, setHoveredMeshId, setLevel, setPrimaryTwinId, menuItems, setExpandable } = useGlobal()

  const videoRef = React.useRef<HTMLVideoElement>(null);
  
  const [hoverRef, isHovered] = useHover();

  const isFocused = isActive || isHovered;

  const to = `/scenario/${id}`;

  useEffect(() => {
    const { current: video } = videoRef;

    if (video) {
      if (isFocused) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [isFocused]);

  const onNavigate = () => {

    // Reset actuator and sensors collapse
    setExpandable(false)
  
    if (labels.scenario === 'Enterprise Scenario') {
      const primaryTwinID = getPrimaryIdByMeshId(menuItems, id)
      // Note: for demo purpose
      smoothNavigate("/")
      setSceneId("3369fc3526f94cd7a77f0735782ef305")
      
      setPrimaryTwinId(primaryTwinID as string)
      // Note: Hardcoded value, for demo purpose
      setLevel('room')
      // id === "Floor6 Enterprise"
    } else {
      smoothNavigate(to);
    }
    setHoveredMeshId(id)
    setMeshToZoom([id])
    
    // setPrimaryTwinId(primaryTwinID as string);
    // setLevel(nodeLevel as string)
  };

  return (
    <div
      ref={hoverRef}
      onClick={onNavigate}
      style={{ zIndex: 2, cursor: "pointer" }}
      className={styles.videoBox}
    >
      <div
        className={styles.videoContainer}
      >
        <div className={cn(styles.videoPlaceholder, {[styles.active]: isFocused})}>
          <video
            ref={videoRef}
            className={styles.video}
            preload="auto"
            muted={true}
            autoPlay={isFocused}
            poster={poster}
          >
            <source src={src} type="video/mp4" />
          </video>
          <Overlay labels={labels} />
        </div>
      </div>
    </div>
  );
};

interface IOverlay {
  labels: TLabels;
  displayPlayButton?: boolean;
}

export const Overlay: React.FC<IOverlay> = ({ labels, displayPlayButton = true }) => {
  const { camera, scenario, showLiveLabel } = labels;
  
  return (
    <div className={styles.overlay}>
      <div className={styles.top}>
        {camera && (
          <>
            <div className={styles.left}>
              <span>{camera}</span>
            </div>
            {showLiveLabel && ( 
            <div className={styles.right}>
              <span>Live</span>
            </div>)}
          </>
        )}
      </div>
      {displayPlayButton && (
        <div className={styles.center}>
          <PlayIcon />
        </div>
      )}
      <div className={styles.bottom}>
        <span>{scenario}</span>
      </div>
    </div>
  );
};

export default Video;
