import { useState, useEffect } from "react";

import throttle from "lodash/throttle";

export const useVideoTimestamp = (
  videoRef: React.RefObject<HTMLVideoElement>
) => {
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    const video = videoRef.current;

    const updateTimestamp = throttle(() => {
      if (video) {
        setTimestamp(video.currentTime);
      }
    }, 1000); // set the throttle time to 500ms

    if (video) {
      video.addEventListener("timeupdate", updateTimestamp);
      return () => video.removeEventListener("timeupdate", updateTimestamp);
    }
  }, [videoRef]);

  return timestamp;
};
