import { useMemo, useState } from "react";

import cn from "classnames";
import { motion } from "framer-motion";

import Delimiter from "../../delimiter/Delimiter";
import { INotification } from "../../../ts/general";

import styles from "./index.module.css";

interface NotificationProps extends Omit<INotification, "timestamp"> {
  isFixedType: boolean | undefined;
}

const notificationVariants = {
  hidden: { y: "-20vh" },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.1,
      damping: 20,
      stiffness: 200,
    },
  },
};

const convertToTimestamp = (seconds: number): string => {
  const absSeconds = Math.abs(seconds);
  const minutes = Math.floor(absSeconds / 60);
  const remainingSeconds = Math.floor(absSeconds % 60);
  const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsString =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return `${minutesString}:${secondsString}`;
};

const RealNotificationItem: React.FC<NotificationProps> = ({
  type = "primary",
  icon: Icon,
  text,
  isFixedType,
  timeUntilNext,
}) => {
  const [maxTime] = useState<number>(timeUntilNext);

  const percentage = useMemo(() => {
    if (timeUntilNext <= 0) {
      return 100;
    }

    return Math.round(((maxTime - timeUntilNext) / maxTime) * 100);
  }, [timeUntilNext]);

  const isMainNotification = ["emergency", "primary"].includes(type);

  const readableTimestamp = convertToTimestamp(timeUntilNext as number);

  return (
    <motion.div
      key={text}
      variants={notificationVariants}
      className={cn(styles.notification, styles[type], {
        [styles.fixedOnTop]: isFixedType,
      })}
    >
      <div className={styles.iconContainer}>{<Icon />}</div>
      <Delimiter />
      <div className={styles.information}>
        <div className={styles.top}>
          <span>{text}</span>
        </div>
        <div className={styles.bottom}>
          {isMainNotification ? (
            <>
              {isFixedType && timeUntilNext > 0 && (
                <span>Starting in {readableTimestamp}</span>
              )}
              {isFixedType && timeUntilNext < 0 && (
                <span>Started {readableTimestamp} ago</span>
              )}
              {!isFixedType && <span>Completed</span>}
            </>
          ) : (
            <div className={styles.progressBar}>
              <div
                className={styles.fill}
                style={{ width: `${percentage}%` }}
              />
              <span>{`${percentage}%`}</span>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default RealNotificationItem;
