import { useGlobal } from "../../contexts/GlobalContext";

import Node from "./Node";

import styles from "./Tree.module.css";

const Tree = () => {
  const { menuItems } = useGlobal();

  return (
    <ul className={styles.tree}>
      {menuItems.map((node: any, index: any) => (
        <Node
          key={`root-node-${index}`}
          node={node}
          defaultOpen={index === 0}
          isLast={index === menuItems.length - 1}
          primaryTwinId={node.primaryTwinID}
          nodeLevel={node.level}
        />
      ))}
    </ul>
  );
};

export default Tree;
