import { DetailedHTMLProps, SelectHTMLAttributes } from "react";
import { isNil } from "lodash";
import styles from "./Dropdown.module.css";

type Props = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  id: string;
  label?: string;
};

const Dropdown = ({ id, label, children, ...props }: Props) => {
  return (
    <>
      {!isNil(label) && <label htmlFor={id}>{label}</label>}
      <select {...props} id={id} className={styles.select}>
        {children}
      </select>
    </>
  );
};

export default Dropdown;
