import { noop } from "lodash";
import { useQuery } from 'react-query'

import Section from "../section/Section";
import { Widget, WidgetSkeleton } from "./Widget";

import styles from "./Stats.module.css";

import { IStatistic } from "../../ts/api";
import {TLevel} from '../../ts/api/index'

import { useGlobal } from "../../contexts/GlobalContext";

const containerStyle = {
  display: "flex",
  gap: "4px",
  flexWrap: "wrap" as any
};

const errorStyle = {
  color: "red",
};


const Stats: React.FC = () => {
  const { api, primaryTwinId, level = 'building' } = useGlobal();


  const buildingLevel = primaryTwinId === 'office' ? 'building' : level;
  const id = primaryTwinId === 'office' ? "intellias.office-buildings.ukraine.lv2" : primaryTwinId

  const { data, isFetching, isSuccess, isFetched, isError } = useQuery({
    queryKey: ['statistics', [primaryTwinId, buildingLevel]],
    queryFn: () => api.getStatistics({ primaryTwinID: id as string, level: buildingLevel as TLevel}),
    refetchInterval: 10_000,
  })

  // @ts-ignore
  const statistics = Array.isArray(data?.data) ? data?.data : [] as IStatistic[]
  
  if(isFetched && statistics?.length == 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Section label="Statistics" actionText="" onActionClick={noop}>
        <div className={styles.statsContainer} style={containerStyle}>
          {isFetching && !isFetched && Array.from({ length: 5 }).map((_, index) => (
            <WidgetSkeleton key={index} />
          ))}
          {isSuccess && statistics?.map(({ name, value, norm, period, type }: any) => (
            <Widget
              key={name}
              name={name}
              value={value}
              norm={norm}
              period={period}
              type={type}
            />
          ))}
          {isError && <span style={errorStyle}>Could not load the statisic information</span>}
        </div>
      </Section>
    </div>
  );
};

export default Stats;
