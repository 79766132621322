import { useState } from 'react';

import { IModalConfig, IModalContext } from './types';
import useTransitionApi from '../../hooks/useTransitionApi';

export const useOpenModal = (): IModalContext => {
  const [state, setState] = useState<IModalConfig>({
    isOpen: false,
  });

  const smoothClose = useTransitionApi(() => {
    setState({ isOpen: false });
  });

  const openModal = (configs: IModalConfig) => {
    setState({
      ...configs,
      isOpen: true,
      cancel: {
        onClick: smoothClose
      },
    });
  };

  return { openModal, state };
};
