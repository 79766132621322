import useTransitionApi from "./useTransitionApi";

type NavigateFunction = (...args: any[]) => void;

function useNavigateTransitionApi(navigate: NavigateFunction) {
  const smoothNavigate = useTransitionApi(navigate);

  return (...args: any[]) => {
    smoothNavigate(...args);
  };
}

export default useNavigateTransitionApi;
