const LightIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2C13.3137 2 16 4.59693 16 7.80041C16 9.47737 15.2546 11.0164 13.7961 12.3942C13.7324 12.4544 13.6831 12.5269 13.6512 12.6065L13.6251 12.6883L12.6891 16.6051C12.5048 17.3763 11.8236 17.935 11.0181 17.9947L10.8748 18H9.12546C8.30655 18 7.59 17.4839 7.34866 16.7385L7.31108 16.6047L6.37626 12.6886C6.34955 12.5766 6.29016 12.4745 6.20516 12.3942C4.8153 11.0819 4.07265 9.62354 4.00507 8.03903L4 7.80041L4.00321 7.60894C4.1077 4.49409 6.75257 2 10 2ZM12.045 15H7.955L8.27386 16.3344L8.30004 16.4305C8.39695 16.7298 8.67583 16.9517 9.0116 16.993L9.12546 17L10.8379 17.0007L10.9442 16.9974C11.2865 16.9721 11.5726 16.7609 11.6854 16.4718L11.7165 16.3727L12.045 15ZM10 3C7.36782 3 5.21188 4.95301 5.0151 7.41357L5.00307 7.62569L4.99977 7.77916L5.00416 7.99642C5.05977 9.30026 5.67758 10.5208 6.89167 11.6671C7.07995 11.8449 7.22191 12.0647 7.30572 12.3078L7.34894 12.4564L7.716 14H12.285L12.6722 12.3851L12.7231 12.2343C12.8091 12.0198 12.9409 11.8265 13.1094 11.6673C14.3825 10.4646 15 9.18054 15 7.80041C15 5.15693 12.7689 3 10 3Z"
        fill="#479EF5"
      />
    </svg>
  );
};

export default LightIcon;
