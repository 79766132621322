import { pipe } from "./utils";
import cameraSVGBox from "./../../resources/images/open_list.svg"
const { BABYLON } = window;


const registerClickEvent = (props) => {
  const { scene, point, callback } = props;

  point.actionManager = new BABYLON.ActionManager(scene);
  
  point.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (evt) => {
      callback(evt);
    })
  );

  return props;
};

  const generateSVGCameraBox = (props) => {
    const { scene, position } = props;
    
    const circle = BABYLON.MeshBuilder.CreateCylinder(
      "circle",
      { height: 0.2, diameter: 3, tessellation: 60 }, // Adjust the tessellation for smoother appearance
      scene
    );

    circle.rotation.x = Math.PI / 2;
    circle.rotation.z = Math.PI / 2;
    const material = new BABYLON.StandardMaterial("svgMaterial", scene);
    
    const svgTexture = new BABYLON.Texture(cameraSVGBox, scene);
  
    material.diffuseTexture = svgTexture;
  
    circle.material = material;

    //hardcoded actual position of the camera
    circle.position = position
      // new BABYLON.Vector3(-115, 4, -75);
  
    return { ...props, point: circle };
  }

export default function renderCameraBox({ scene, roomMesh, callback, position }) {
  
  //if floor1 is not picked
  // if(roomMesh.name !== "Wall Mesh.002" && roomMesh.name !== "Floor 1 Factorium" &&  roomMesh.name !== "Floor 1 Intelligence") {
  //   return
  // }
  
  if(roomMesh.name !== 'Floor 1 ShowRoom') {
    return
  }

  return pipe(
   generateSVGCameraBox,
    registerClickEvent
  )({
    scene,
    roomMesh,
    position,
    callback: (evt) => {
      callback("/scenario/Reception");
    },
  });
}
