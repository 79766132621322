import { useGlobal } from "../../contexts/GlobalContext";
import realTimeImage from "../../resources/images/room0.png"
import enterpriseImage from "../../resources/images/room3.png";
import RealTimeVideo from "./RealTimeVideo";
import Video from "./Video";

import styles from './VideoBar.module.css';
const roomImages = [realTimeImage, enterpriseImage];
const VideoBar: React.FC = () => {
  const { selectedMeshId, hoveredMeshId, videosConfig } = useGlobal();
  
  // Note: for demo purpose. Remove "Floor6 Atomic" from the video
  return (
    <div className={styles.videobarContainer}>
      {Object.entries(videosConfig).map(
        ([
          id,
          {
            mainPage: { src, labels, isRealtime },
          },
        ],i) => (
          <>
           {/* <Video
              id={id}
              key={id}
              src={src}
              labels={labels}
              isActive={[selectedMeshId, hoveredMeshId].includes(id)}
              poster={!src ? roomImages[i % roomImages.length] : ''}
            />   */}
            {
              isRealtime ?
                <RealTimeVideo
                  id={id}
                  src={src}
                  key={id}
                  labels={labels}
                  isActive={[selectedMeshId, hoveredMeshId].includes(id)}
                  poster={roomImages[i % roomImages.length]}
                /> :
                <Video
                  id={id}
                  key={id}
                  src={src}
                  labels={labels}
                  isActive={[selectedMeshId, hoveredMeshId].includes(id)}
                  poster={!src ? roomImages[1] : ''}
                />
            }
          </>
        )
      )}
    </div>
  );
};

export default VideoBar;
