import {map, isEqual, find, head, get} from 'lodash'
import {TNodes, TSidebarTree} from '../components/tree/generateSideBarItems'

export default function pickActiveMenuItemByMeshId(menuItems: TSidebarTree[], meshToZoom: string): TNodes {
    const allMeshes = map(menuItems, 'nodes').flat();
  
    return find(allMeshes, mesh => {
      const objectId = head(get(mesh, 'objectIDs', []));

      return isEqual(objectId, meshToZoom)
    }) as TNodes ?? {primaryTwinID: ''}
  }