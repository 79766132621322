import { useMemo } from "react";
import { head, values } from 'lodash'
import { useQuery } from 'react-query'

import { useGlobal } from "../../contexts/GlobalContext";
import Device, { DeviceSkeleton } from "./Device";
import Section from "../section/Section";
import Expandable from '../expandable/Expandable';

import styles from "./Hardware.module.css";
import { useModal } from '../modal';
import useTransitionApi from '../../hooks/useTransitionApi';
import { HardwareTable } from './HardwareTable';

import type { IActuator, ISensor, TLevel } from "../../ts/api";
import getPrimaryIdByMeshId from '../../helpers/getPrimaryIdByMeshId'
import convertPrimaryTwinIdToUID from "../../helpers/convertPrimaryTwinIdToUID";

const Hardware = () => {
  const { openModal } = useModal();
  const { menuItems, meshToZoom, actuators, sensors, api, setActuators, setSensors, config, level, floorLevel, primaryTwinId } = useGlobal()
  const openModalSmoothly = useTransitionApi(openModal);

  const computedPrimaryTwinId = useMemo(() => {
    const meshObjectIdToZoom = head(meshToZoom) as string

    return getPrimaryIdByMeshId(menuItems, meshObjectIdToZoom)
  }, [menuItems, meshToZoom]);
  
  const isOfficeLevel = primaryTwinId === 'office';
  
  const entityId = isOfficeLevel ? config.hqBuildingTwinId : computedPrimaryTwinId || primaryTwinId
  const uuid = convertPrimaryTwinIdToUID(entityId)
  const computedLevel = isOfficeLevel ? 'building' : level || primaryTwinId
  const entityLevel = `${computedLevel}s` as TLevel;
  
  const actuatorsStatus = useQuery({
    queryKey: ['actuators', entityId, entityLevel],
    queryFn: () => api.getActuators({id: entityId, level: entityLevel}),
    refetchInterval: 4_000,
    onSuccess: (response: any) => {
      const details = response?.data ?? []

      setActuators({ uuid, details })
    },
    cacheTime: 1 // do not cache the data
  })

  const sensorsStatus = useQuery({
    queryKey: ['sensors', entityId, entityLevel],
    queryFn: () => api.getSensors({id: entityId, level: entityLevel}),
    refetchInterval: 4_000,
    onSuccess: (response: any) => {
      const details = response?.data ?? []

      setSensors({ uuid, details })
    },
    cacheTime: 1 // do not cache the data
  })

  const actuatorsCollection = actuators?.[uuid] ?? {}
  const sensorsCollection = sensors?.[uuid] ?? {}

  const actuatorsList = values(actuatorsCollection)
  const sensorsList = values(sensorsCollection)

  const showAllHandler = () => {
    openModalSmoothly({
      title: 'Hardware',
      content: (
        <HardwareTable
          actuatorsList={actuatorsList as IActuator[]}
          sensorsList={sensorsList as ISensor[]}
        />
      )
    });
  };


  return (
    <Section label="Hardware" actionText="Show all" onActionClick={showAllHandler}>
      <div className={styles.hardware}>
        <Expandable title="Actuators">
          {actuatorsStatus.isLoading && Array.from({ length: 5 }).map((_, index) => (
            <DeviceSkeleton key={index} />
          ))}
          {
            !actuatorsStatus.isLoading && actuatorsList?.map(({ id, name, isOnline, priority, value, deviceTypeCode, displayLocation }: any) => (
              <Device
                key={id}
                name={name}
                isOnline={isOnline}
                priority={priority}
                value={value}
                deviceTypeCode={deviceTypeCode}
                displayLocation={displayLocation}
              />
            ))
          }
        </Expandable>
        <Expandable title="Sensors">
          {sensorsStatus.isLoading && Array.from({ length: 5 }).map((_, index) => (
            <DeviceSkeleton key={index} />
          ))}
          {
            !sensorsStatus.isLoading && sensorsList?.map(({ id, name, isOnline, priority, value, deviceTypeCode, displayLocation }: any) => (
              <Device
                key={id}
                name={name}
                isOnline={isOnline}
                priority={priority}
                value={value}
                deviceTypeCode={deviceTypeCode}
                displayLocation={displayLocation}
              />
            ))
          }
        </Expandable>
      </div>
    </Section>
  );
};

export default Hardware;
