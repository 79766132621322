import { useParams } from "react-router-dom";
import Scenario from "./Scenario"
import {
    TMeshIdKey,
  } from "../../ts/general";

// Note: in order to react-router-dom v6, changing params in the url doesn't affect re-rendering for element component
// providing `key` attribute here, allow us in a faster and easier way trigger re-render, whenever `scenarioId` is changed.
// Note: faster and easier means the less code - desired result :)
function ScenarioWrapper() {
    const { scenarioId } = useParams<{ scenarioId: TMeshIdKey }>();

    return <Scenario key={scenarioId}/>
}

export default ScenarioWrapper