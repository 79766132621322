import { Outlet } from "react-router-dom";
import Page from "../components/shared/page/Page";
import Navigation from "../components/navigation/Navigation";

import NotificationScenarioController from "../components/notifications/NotificationScenarioController"
import Tree from "../components/tree/Tree";
import Dropdown from "../components/dropdown/Dropdown";
import VideoBar from "../components/videoBar/VideoBar";
import LogoIcon from "../components/icons/logo";
import Hardware from '../components/hardware/Hardware'
import Sidebar from '../components/shared/sidebar/Sidebar'
import Stats from '../components/stats/Stats'

import styles from "./main/Main.module.css";

function Layout() {
    return (
        <Page>
            <div className={styles.left}>
                <div className={styles.logo}>
                    <LogoIcon />
                    <span>Powered by Intel® SceneScape</span>
                </div>
                <div className={styles.tools}>
                    <div className={styles.project}>
                        <span className={styles.label}>Location</span>
                        <Dropdown id="dropdown-default" value="Lviv Office" disabled={true}>
                            <option value="Lviv Office">Lviv Office</option>
                        </Dropdown>
                    </div>
                    <div className={styles.delimiter} />
                    <div className={styles.facilityLevels}>
                        <span className={styles.label}>Facility levels</span>
                        <Tree />
                    </div>
                </div>
                <div className={styles.navigation}>
                    <Navigation />
                </div>
            </div>
            <div className={styles.top}>
                <VideoBar />
            </div>
            <Sidebar>
                <NotificationScenarioController />
                <div className={styles.delimiter} />
                <div className={styles.hardware}>
                    <Hardware />
                </div>
            </Sidebar>

            <div className={styles.bottom}>
                <div className={styles.stats}>
                    <Stats />
                </div>
            </div>
            <div className={styles.center}>
                <Outlet />
            </div>
        </Page>
    )

}

export default Layout