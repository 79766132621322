const LightsDimmedOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=lights_dimmed">
      <path
        id="icon/image/brightness_6_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.68994V3.99994H15.31L12 0.689941L8.69 3.99994H4V8.68994L0.690002 11.9999L4 15.3099V19.9999H8.69L12 23.3099L15.31 19.9999H20V15.3099L23.31 11.9999L20 8.68994ZM18 14.4799V17.9999H14.48L12 20.4799L9.52 17.9999H6V14.4799L3.52 11.9999L6 9.51994V5.99994H9.52L12 3.51994L14.48 5.99994H18V9.51994L20.48 11.9999L18 14.4799ZM17.5 11.9999C17.5 15.0299 15.03 17.4999 12 17.4999V6.49994C15.03 6.49994 17.5 8.96994 17.5 11.9999Z"
        fill="white"
      />
    </g>
  </svg>
);

export default LightsDimmedOutlined;
