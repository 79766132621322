import { ReactNode } from 'react';

import { ModalContext } from './context';
import { IModalContext } from './types';
import { ModalComponent } from './ModalComponent';
import { useOpenModal } from './useOpenModal';

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps): JSX.Element => {
  const { openModal, state: modalState }: IModalContext = useOpenModal();

  const modalContextValue: IModalContext = {
    openModal: openModal,
    state: modalState
  };

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
      <ModalComponent {...modalState} />
    </ModalContext.Provider>
  );
};