const StraightIcon = () => {
  return (
    <svg
      width="2"
      height="32"
      viewBox="0 0 2 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.00001 20.0005L1 -0.000488281L1 32.0004" stroke="#5C5C5C" />
    </svg>
  );
};

export default StraightIcon;
