const CaretInactiveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_253_7190)">
        <path
          d="M6.66675 11.3334L10.0001 8.00002L6.66675 4.66669V11.3334Z"
          fill="#98A2B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_253_7190">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CaretInactiveIcon;
