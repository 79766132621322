import { get, isUndefined, keyBy, omit } from "lodash";
import { GlobalContextState } from "../../ts/contexts";
import convertPrimaryTwinIdToUID from "../../helpers/convertPrimaryTwinIdToUID";


interface GlobalReducerAction {
  type: string;
  payload?: any;
}

export const reducer: (
  state: GlobalContextState,
  action: GlobalReducerAction
) => GlobalContextState = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case "SET_SCENE_ID": {
      return {
        ...state,
        selectedMeshId: null,
        sceneId: payload
      };
    }
    case "SET_SELECTED_MESH_ID": {
      return {
        ...state,
        selectedMeshId: payload
      };
    }
    case "SET_HOVERED_MESH_ID": {
      return {
        ...state,
        hoveredMeshId: payload
      };
    }
    case "SET_MENU_ITEMS": {
      return {
        ...state,
        menuItems: payload
      };
    }
    case "SET_MESH_TO_ZOOM": {
      return {
        ...state,
        meshToZoom: payload
      };
    }
    case "SET_SENSORS": {
      const prevSensors = get(state.sensors, payload.uuid, {});
      const sensorsCollection = keyBy(payload.details, "id");
      
      return {
        ...state,
        sensors: {
          [payload.uuid]: {
            ...prevSensors,
            ...sensorsCollection
          }
        }
      };
    }
    
    case "SET_ACTUATORS": {
      const prevActuators = get(state.actuators, payload.uuid, {});
      const actuatorsCollection = keyBy(payload.details, "id");
      
      return {
        ...state,
        actuators: {
          [payload.uuid]: {
            ...prevActuators,
            ...actuatorsCollection
          }
        }
      };
    }
    
    case "SET_NOTIFICATIONS": {
      const notifications = get(payload, `message.data`, []);
      
      const uuid = isUndefined(payload.uuid)
        ? convertPrimaryTwinIdToUID(state.primaryTwinId as string)
        : payload.uuid;
      
      const prevNotifications = get(state.notifications, uuid, {});
      const notificationCollection = keyBy(notifications, "id");
      
      return {
        ...state,
        notifications: {
          [uuid]: {
            ...prevNotifications,
            ...notificationCollection
          }
        }
      };
    }
    
    case "REMOVE_NOTIFICATION" : {
      const { uuid, id } = payload;
      const prevNotifications = get(state.notifications, uuid, {});
      const withOmmited = omit(prevNotifications, [id]);
      
      return {
        ...state,
        notifications: {
          [uuid]: {
            ...withOmmited
          }
        }
      };
    }
    
    case "SET_PRIMARY_TWIN_ID": {
      return {
        ...state,
        primaryTwinId: payload
      };
    }
    case "SET_LEVEL": {
      return {
        ...state,
        level: payload
      };
    }
    
    case "PERSIST_REAL_SCENARIO_NOTIFICATIONS": {
      const updatedList = payload.length === 0
        ? []
        : payload.concat(state.realScenarioNotifications);
      
      return {
        ...state,
        realScenarioNotifications: updatedList
      };
    }
    case "SET_FLOOR_LEVEL": {
      //set floor lv, floor1, floor6, ... for filtering hardware
      const extractFloor = (payload: string) => {
        if (!payload) return "office";
        const match = payload.match(/floor(\d+)/i);
        if (match) {
          return `floor${match[1]}`;
        }
        return "office";
      };
      
      return {
        ...state,
        floorLevel: extractFloor(payload)
      };
    }
    case "SET_EXPANDABLE_BOOLEAN": {
      return {
        ...state,
        expandableSwitch: payload
      };
    }
    
    default:
      return state;
  }
};
