const TemperatureOutlined = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Property 1=temperature">
      <path
        id="icon/device/thermostat_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C13.66 2 15 3.34 15 5V13C16.21 13.91 17 15.37 17 17C17 19.76 14.76 22 12 22C9.24 22 7 19.76 7 17C7 15.37 7.79 13.91 9 13V5C9 3.34 10.34 2 12 2ZM12 4C11.45 4 11 4.45 11 5V11H13V9H12V8H13V6H12V5H13C13 4.45 12.55 4 12 4Z"
        fill="white"
      />
    </g>
  </svg>
);

export default TemperatureOutlined;
