import React from "react";
import { createRoot } from "react-dom/client";
import {
  PublicClientApplication,
  Configuration,
  type PopupRequest,
  BrowserCacheLocation,
  AuthenticationResult,
  SilentRequest,
} from "@azure/msal-browser";

import { MsalProvider } from "@azure/msal-react";

import App from "./App";
import { GlobalConfig, SceneInfo } from "./ts/general";
import axios, { AxiosInstance } from 'axios';

import "./custom.css";

const scenes: SceneInfo[] = [
    {
        id: "d754039ca0614b8d8e4deccc1ae9f285",
        displayName: "Office",
        name: "office"
    },
    {
        id: "40b0896ec1314ef5a1f2bea47b3e16ec",
        displayName: "Floor 1",
        name: "floor1"
    },
    {
        id: "52c5932057b14a8a8029e8864a100e32",
        displayName: "Floor 2",
        name: "floor2"
    },
    {
        id: "bd014999b97a40608c1ff2b181a394c2",
        displayName: "Floor 3",
        name: "floor3"
    },
    {
        id: "f35380abd4e54b80b816961e225799db",
        displayName: "Floor 4",
        name: "floor4"
    },
    {
        id: "ec29ec4ac6ab436587bb700ba7de2c4e",
        displayName: "Floor 5",
        name: "floor5"
    },
    {
        id: "3369fc3526f94cd7a77f0735782ef305",
        displayName: "Floor 6",
        name: "floor6"
    }
];

const bfeBaseUrl = process.env.REACT_APP_BFE_BASE_URL;

const axiosInstance: AxiosInstance = axios.create({
    baseURL: bfeBaseUrl,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosInstance.get<GlobalConfig>("/viewer/options")
    .then(async function (response) {
     
      const config = {
        ...response.data,
        bfeBaseUrl
      };

      const msalConfig: Configuration = {
        auth: {
          clientId: config.azureConfig.adAuth.clientId!,
          authority: config.azureConfig.adAuth.authority!,
          redirectUri: config.azureConfig.adAuth.redirectUri!,
          protocolMode: "OIDC",
          postLogoutRedirectUri: "/",
        },
        cache: {
          cacheLocation: BrowserCacheLocation.SessionStorage,
        },
      };

      const popupRequest: PopupRequest = {
        scopes: ["openid", "profile", "user.read"],
      };
  
      const msalInstance = new PublicClientApplication(msalConfig); 
      await msalInstance.initialize()

      async function getTokenPopup(request: PopupRequest | SilentRequest, account?: string) {
        request.account = account as any;  
    
        return await msalInstance.acquireTokenSilent({...request, account: JSON.parse(sessionStorage.getItem("msalAccount") || "{}"), forceRefresh: false})
        .catch(async (error) => {
            console.log("silent token acquisition fails.");
            if (error) {
                console.log("acquiring token using popup");
                return msalInstance.acquireTokenPopup(request)
                  .then(response => {
                    msalInstance.setActiveAccount(response.account);
                    sessionStorage.setItem("msalAccount", JSON.stringify(response.account));
                    return response
                  })
                .catch(error => {
                    console.error(error);
                });
            } else {
                console.error(error);
            }
        });
      }
      const AuthResult = await getTokenPopup(popupRequest)
  
      console.log(`config: ${JSON.stringify(config)}`, { config });
      console.log(`scenes: ${JSON.stringify(scenes)}`, { scenes })
  
      const rootElement = document.getElementById("app");
      const root = createRoot(rootElement as Element);

      root.render(
          <MsalProvider instance={msalInstance}>
            <App
              appData={{ 
                config, 
                popupRequest, 
                msalConfig, 
                scenes,
                authToken: AuthResult as AuthenticationResult 
              }}
            />
          </MsalProvider>
      );
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
