import { WebPubSubClient } from "@azure/web-pubsub-client";
import moment from 'moment';
import { default as bigInt, BigInteger } from 'big-integer';

enum PubSubroups {
    receptionPeopleLocationStream = 'intellias.office-buildings.ukraine.lv2.sceenscape1.people-location-sensor1--people-location-stream',
    enterpriseMeetingRoom = 'intellias.office-buildings.ukraine.lv2.floor6.meeting-room-2'
}

interface LocationDto {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface ObjectDto {
    id: number;
    category?: string;
    confidence: number;
    centerOfMass?: LocationDto;
    boundingBoxMeters: LocationDto;
    translation: undefined;
    velocity: number[];
    // "visibility": [],
    // "camera_bounds": {},
    firstSeen: string;
}

interface PeopleLocationTelemetryDto {
    timestamp: string;
    id?: string;
    objects: ObjectDto[];
    rate: number;
}

export async function createConnectedClient(negotiationApiBaseUrl: string, idToken: string): Promise<WebPubSubClient> {
    const client = new WebPubSubClient({
        getClientAccessUrl: async () => {
            let url: string;
            try {
                const headers = new Headers();
                const bearer = "Bearer " + idToken;
                headers.append("Authorization", bearer);
                const options = {
                    method: "GET",
                    headers: headers
                };

                let res = await fetch(`${negotiationApiBaseUrl}/notification/negotiate`, options);
                if (!res.ok) {
                    throw new Error(`Failed to negotiate web socket uri with server. Status: ${res.status}, Message: ${res.statusText}`)
                }

                url = await res.text();
            } catch (error) {
                console.error(error);
                throw error;
            }

            return url!;
        }
    },
        {
            autoReconnect: true,
            autoRejoinGroups: true,
            reconnectRetryOptions: {
                mode: "Exponential"
            }
        });


    const lagTotals: {
        count: number;
        sum: BigInteger;
        avg: number;
        max: number;
        min: number;
        lastObservedTimestamp: moment.Moment;
        countShuffled: number;
    } = {
        count: 0,
        sum: bigInt(0),
        avg: 0,
        max: 0,
        min: 0,
        lastObservedTimestamp: moment().add(-1, 'days'),
        countShuffled: 0
    };


      

    // client.on('group-message', args => {

    //     console.log('Messaging::Outer', {args})

    //     const customEvent = new CustomEvent('subscribe:group-message', {
    //         detail: args.message,
    //         bubbles: true, // Whether the event bubbles up through the DOM or not
    //         // cancelable: true // Whether the event can be canceled using event.preventDefault()
    //       });

    //       console.log("Messaging::NewEventDispatched")
    //     document.dispatchEvent(customEvent);

    //     // if (args.message.group === PubSubroups.enterpriseMeetingRoom) {
    //     //     // TODO: handle meeting room telemetry here
    //     //     console.log(args.message.data);
    //     // }
    //     // else if ((args.message.group === PubSubroups.receptionPeopleLocationStream)
    // //     // ) {
    //         console.log(args.message.data);

    //         const locationTelemetry = args.message.data as PeopleLocationTelemetryDto;

    //         const timeStamp = moment(locationTelemetry.timestamp);
    //         const rawDelay = moment().diff(timeStamp);

    //         lagTotals.count += 1;
    //         lagTotals.sum = lagTotals.sum.add(rawDelay);
    //         lagTotals.avg = lagTotals.sum.divide(lagTotals.count).valueOf();
    //         lagTotals.max = Math.max(lagTotals.max, rawDelay);
    //         lagTotals.min = Math.min(lagTotals.min, rawDelay);

    //         if (lagTotals.lastObservedTimestamp > timeStamp) {
    //             lagTotals.countShuffled += 1;
    //         }
    //         lagTotals.lastObservedTimestamp = timeStamp;

    //         console.log(`Lag: ${moment.duration(rawDelay).toISOString()};`);
    //         const readableLagTotals = {
    //             count: `Message count: ${lagTotals.count}`,

    //             min: `Min lag: ${moment.duration(lagTotals.min).toISOString()}`,
    //             avg: `Avg lag: ${moment.duration(lagTotals.avg).toISOString()}`,
    //             max: `Max lag: ${moment.duration(lagTotals.max).toISOString()}`,

    //             countShuffled: `Count of shuffled messages ${lagTotals.countShuffled}`
    //         };
    //         console.log(readableLagTotals);
    //     // }
    // });

    // client.on('server-message', args => console.log(args.message.data));

    // client.on('connected', _args => console.log('Connected to stream web socket'));

    // client.on('disconnected', _args => console.log('Disconnected from stream web socket'));

    // await client.start();

    // await client.joinGroup(PubSubroups.receptionPeopleLocationStream);

    // This may be too secific:  it might be necessary to have a single group for entire building to handle updates for all room on a single view

    // await client.joinGroup("intellias.office-buildings.ukraine.lv2.floor6.meeting-room-2");

    return client;
}